import { observable, set, toJS } from 'mobx'
import api from '../utils/api'
import modalAlert, { modalInfo } from '../components/Alert'
import modalConfirm from '../components/Confirm'
import { SuccessModal } from '../modals/TpeModal'
import moment from 'moment/moment'
import { number } from '../utils/numeral'
import LoadingStore from './LoadingStore'


const props = {
    pageReady: false,
    activationCode: '',
    numCarte: '',
    soldePme1 : null,
    soldePme2 : null,
    clientNom : null,
    clientPrenom : null,
    columns: [],
    filters: {},
    activeFilters: {},
    currentRow: {},
    data: [],
    listClientStatuts: [],
    pagination: {},
    NumCarte: '',
    isOpenEditCreateModal: false,
    pagination: {},
    columns: [
        {
            label: 'Date/Heure',
            key: 'DateTransaction',
            render: value => {
                // console.log(value)
                if (moment(value).isValid())
                    return moment(value).format('DD/MM/YYYY [à] HH[h]mm')
                else
                    return "-"
            },
        },
        {
            label: 'Solde PME1',
            field: 'NouveauPME1',
            key: 'NouveauPME1',
            render: value => number(value)
        },
        {
            label: 'Solde PME2',
            field: 'NouveauPME2',
            key: 'NouveauPME2',
            render: value => number(value)
        },
        {
            label: 'Crédit PME1',
            field: 'creditPme1',
            key: 'creditPme1',
            render: value => number(value)
        },
        {
            label: 'Débit PME1',
            field: 'debitPme1',
            key: 'debitPme1',
            render: value => number(value)
        },
        {
            label: 'Crédit PME2',
            field: 'creditPme2',
            key: 'creditPme2',
            render: value => number(value)
        },
        {
            label: 'Débit PME2',
            field: 'debitPme2',
            key: 'debitPme2',
            render: value => number(value)
        },
        {
            label: 'Enseigne',
            field: 'NomCommercant',
            key: 'NomCommercant',
        },

    ],
}



const CardStore = observable({
    /***** Props ******/
    props: props,

    /***** Methods ******/
    async init() {
        //Initialisation pagination
        this.props.pagination = {
            index: 0,
            offset: 20,
            total: null,
            onPaginationChange: (action, index) => this.onPaginationChange(action, index)
        }

    },
    setNumCarte(card) {
        console.log(card)
        this.props.NumCarte = card
    },
    onPaginationChange(action, index) {
        switch (action) {
            case 'next':
                this.props.pagination.index++
                this.getTransacs()
                break;
            case 'prev':
                if ((this.props.pagination.index - 1) >= 0)
                    this.props.pagination.index--
                this.getTransacs()
                break;
            case 'goto':
                this.props.pagination.index = index
                this.getTransacs()
                break;

            default:
                break;
        }

    },
    async sendSmsCreateCard(tel,montant){
        LoadingStore.on()
        const res = await api.post('', {
            action: 'sendSmsCreateCard',
            tel: tel,
            montant: montant
        })

        if (res.data && res.data.success) {
            modalAlert({
                title: 'Succes',
                typeAlert: 'success',
                text: res.data.msg
            })
        }
        else if (typeof res.data.success == 'boolean') {
            modalAlert({
                title: 'Erreur',
                typeAlert: 'danger',
                text: res.data.msg
            })
        }
        LoadingStore.off()
    },
    async fetchReseaux(search = null) {
        const key = 'nom'
        const res = await api.post('', {
            action: 'getNetworks',
            search: search,
            key: key
        })
        if (res.data && res.data.success) {
            // LoadingStore.off()
            return res.data.networks.map((item => ({
                id: item.id,
                name: item.nom,
                ...item
            })))
        }
        else if (typeof res.data.success == 'boolean') {
            modalAlert({
                title: 'Erreur',
                typeAlert: 'danger',
                text: "Une erreur est survenue"
            })
            // LoadingStore.off()
            return {}
        }
    },
    async getCustomersByPhone(tel) {
        const key = 'nom'
        const res = await api.post('', {
            action: 'getCustomersByPhone',
            tel: tel
        })
        if (res.data && res.data.success) {
            // LoadingStore.off()
            return res.data.customers
        }
        else if (typeof res.data.success == 'boolean') {
            modalAlert({
                title: 'Erreur',
                typeAlert: 'danger',
                text: "Une erreur est survenue"
            })
            // LoadingStore.off()
            return {}
        }
    },

    async createNewCard(values, props) {
        const { setFieldValue } = props
        const res = await api.post('', {
            action: 'generateNewCardNumber',
            porteur_id: values['porteur_id'],
            commercant_id: values['commercant'].id,
            reseau_id: values['reseau'].id,
            force: true,
            code: values['code'],
            nom: values['nom'],
            prenom: values['prenom'],
            email: values['email'],
            adresse: values['adresse'],
            date_naiss: values['date_naiss'],
            tel: values['tel'],
            cp: values['cp'],
            ville: values['ville'],
        })

        if (res.data && res.data.success) {
            // console.log(res.data)
            modalAlert({
                title: 'Succes',
                typeAlert: 'success',
                text: res.data.msg
            })

            setFieldValue('card', res.data.card)

            return new Promise(async (resolve, reject) => resolve())
        }
        else if (typeof res.data.success == 'boolean') {
            modalAlert({
                title: 'Erreur',
                typeAlert: 'danger',
                text: res.data.msg
            })

            setFieldValue('code', '')



            return new Promise(async (resolve, reject) => reject())
        }
    },
    async sendEmail(values, reset) {
        LoadingStore.on()
        const res = await api.post('', {
            action: 'sendEmail',
            email: values['email']
        })

        if (res.data && res.data.success) {
            reset()
            modalAlert({
                title: 'Succes',
                typeAlert: 'success',
                text: res.data.msg
            })
        }
        else if (typeof res.data.success == 'boolean') {
            modalAlert({
                title: 'Erreur',
                typeAlert: 'danger',
                text: res.data.msg
            })
        }

        LoadingStore.off()
    },
    async checkIfCardAlreadyExist(props) {
        // console.log(props)
        const { values, setFieldValue } = props
        const res = await api.post('', {
            action: 'checkIfCardAlreadyExist',
            email: values['email']
        })

        if (res.data && res.data.success) {
            console.log(res.data)
            if (res.data.userAlreadyExists) {
                setFieldValue('porteur_id', res.data.user.id)
                setFieldValue('nom', res.data.user.nom)
                setFieldValue('prenom', res.data.user.prenom)
                setFieldValue('tel', res.data.user.tel)
                setFieldValue('date_naiss', res.data.user.date_naiss)
                setFieldValue('gender', parseInt(res.data.user.gender))
                setFieldValue('adresse', res.data.user.adresse)
                setFieldValue('cp', res.data.user.cp)
                setFieldValue('ville', res.data.user.ville)
            }
        }
        else {

        }

        return new Promise(async (resolve, reject) => {


            if (!res.data.alreadyHasCard) {
                resolve()
            }
            else {
                const suiv = await modalConfirm({
                    title: 'Attention',
                    text: "Vous possédez déjà une carte pour cette enseigne, souhaitez-vous tout de même continuer?"
                })

                if (suiv) {
                    resolve()
                }
                else
                    reject();
            }
        })

    },
    async getUserInfos(values, props) {

        const { setFieldValue } = props

        const res = await api.post('', {
            action: 'getUserInfos',
            porteur_id: values['porteur'],
        })

        if (res.data && res.data.success) {
            console.log(res.data)
            setFieldValue('nom', res.data.nom)
            setFieldValue('prenom', res.data.prenom)
            setFieldValue('email', res.data.email)
            setFieldValue('adresse', res.data.adresse)
            setFieldValue('cp', res.data.cp)
            setFieldValue('tel', res.data.tel)
            setFieldValue('ville', res.data.ville)
            setFieldValue('date_naiss', res.data.date_naiss)
            return false
        }
        else if (typeof res.data.success == 'boolean') {
            modalAlert({
                title: 'Erreur',
                typeAlert: 'danger',
                text: "Une erreur est survenue"
            })
            // LoadingStore.off()
            return {}
        }
    },
    async sendActivationCode(values, props) {
        // console.log(props)
        const { setFieldValue, setErrors } = props
        // console.log(setFieldValue)
        setFieldValue('code', '')
        const res = await api.post('', {
            action: 'sendActivationCode',
            tel: values['tel']
        })

        if (res.data && res.data.success) {
            console.log(res.data)
            setFieldValue('code', res.data.code)
            return false
        }
        else {
            modalAlert({
                title: 'Erreur',
                typeAlert: 'danger',
                text: "Une erreur est survenue"
            })
            // LoadingStore.off()
            return {}
        }
    },
    async transac(values,card, handleReset) {
        LoadingStore.on()
        console.log('CardStore transasc',card)
        const res = await api.post('', {
            action: 'transac',
            NumCarte: card,
            TypeTransac: values['TypeTransac'],
            MontantTransac: parseFloat(values['MontantTransac'].replace(',', '.')) * 100
        })

        if (res.data && res.data.success) {
            console.log(res.data)
            modalInfo({
                title: '',
                typeAlert: 'secondary',
                size: 'md',
                component: <SuccessModal data={res.data}></SuccessModal>
            })
            handleReset()
        }
        else {
            modalAlert({
                title: 'Erreur',
                typeAlert: 'danger',
                text: res.data.msg
            })
        }
        LoadingStore.off()
    },
    async checkPin(card,code) {
        const res = await api.post('', {
            action: 'checkPin',
            NumCarte: card,
            code: code
        })

        if (res.data && res.data.success) {
           return true
        }
        else {
            modalAlert({
                title: 'Erreur',
                typeAlert: 'danger',
                text: res.data.msg
            })
            return false
        }
    },
    async getTransacs() {
        LoadingStore.on()
        const res = await api.post('', {
            action: 'getTransacs',
            NumCarte: this.props.NumCarte,
            pagination: this.props.pagination
        })

        if (res.data && res.data.success) {
            console.log(res.data)
            this.props.data = !res.data.rows ? [] : res.data.rows
            this.props.soldePme1 = res.data.pme1
            this.props.soldePme2 = res.data.pme2
            this.props.clientPrenom = res.data.clientPrenom
            this.props.clientNom = res.data.clientNom
            this.props.pagination.total = res.data.totalCount
            //    handleReset()
        }
        else {
            modalAlert({
                title: 'Erreur',
                typeAlert: 'danger',
                text: res.data.msg
            })
        }
        LoadingStore.off()
    },
    async fetchCommercants(search = null, reseau = null) {
        const key = 'nom'
        const res = await api.post('', {
            action: 'getShops',
            search: search,
            key: key,
            reseau_id: reseau.id
        })
        if (res.data && res.data.success) {
            // LoadingStore.off()
            return res.data.shops.map((item => ({
                id: item.id,
                name: item.nom,
                ...item
            })))
        }
        else if (typeof res.data.success == 'boolean') {
            modalAlert({
                title: 'Erreur',
                typeAlert: 'danger',
                text: "Une erreur est survenue"
            })
            // LoadingStore.off()
            return {}
        }
    },
    nextPageTransac() {
        this.props.pageTransacIndex = this.props.pageTransacIndex + 1
        this.props.pageTransacStart = this.props.pageTransacIndex * this.props.pageTransacLimit
        this.getTransacs()
    },
    prevPageTransac() {
        this.props.pageTransacIndex = this.props.pageTransacIndex - 1
        this.props.pageTransacStart = this.props.pageTransacIndex * this.props.pageTransacLimit
        this.getTransacs()
    },
})

export default CardStore