import React, { useEffect, useRef, useState } from 'react'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import classnames from 'classnames'
import { PopoverHeader, PopoverBody, Popover, DropdownToggle, DropdownItem, DropdownMenu, Dropdown } from 'reactstrap';
import { observer } from 'mobx-react';
import { createPortal } from 'react-dom';
import { toJS } from 'mobx';
import { ChevronLeft, ChevronRight, RefreshCw, MoreHorizontal, Minus, ArrowUpRight, ArrowDownRight, Frown } from 'react-feather';
import { Filter } from './Filter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faColumns, faPlusCircle } from '@fortawesome/free-solid-svg-icons';


const DataTableSimple = observer((props) => {

    const {
        maxHeight,
        btnClassName,
        columns,
        data,
        hideColumns,
        hideFilters,
        pagination,
        currentRole,
        filters,
        applyFilter,
        toggleEdit,
        toggleSort,
        toggleColumn,
        hidePaginationLabel,
        doubleClick,
        fontSm
    } = props

    console.log('RENDER')
    console.log(toJS(columns))

    const inputPageRef = useRef()

    const doDoubleClick = (row) => {
        if (doubleClick)
            doubleClick(row)
    }

    useEffect(() => {
        if (inputPageRef.current)
            inputPageRef.current.value = pagination.index
        // getData()
    }, [pagination.index])

    return (
        <>
            {


                <>
                    <div className="row">
                        <div className="col-md-6 d-flex">
                            {
                                columns && !hideColumns &&
                                <div className="px-1">
                                    <ColumnsToggle columns={columns} toggleColumn={toggleColumn}></ColumnsToggle>
                                </div>
                            }
                            {
                                filters && !hideFilters &&
                                <div className="px-1">
                                    <Filter
                                        filters={filters}
                                        applyFilter={(values) => applyFilter(values)}
                                    ></Filter>
                                </div>
                            }
                        </div>
                        <div className="col-md-6 d-flex justify-content-end">

                            {
                                toggleEdit &&
                                <div className="px-1">
                                    <button className="btn btn-sm btn-success" onClick={() => toggleEdit()}>
                                        <FontAwesomeIcon icon={faPlusCircle} className="mr-2"></FontAwesomeIcon>
                                        Nouveau
                                    </button>
                                </div>
                            }
                        </div>
                    </div>
                    {
                        data.length == 0 &&
                        <div className="row">
                            <div className="col-12 mt-5 text-center">
                                <Frown size={60} style={{ color: '#f1f1f1' }}></Frown>
                            </div>
                            <div className="col-12 mt-2 text-center h4">
                                <span style={{ color: '#c7c7c7' }}>Aucune donnée disponible.</span>
                            </div>
                        </div>
                    }
                    {
                        data.length > 0 &&
                        <>
                            <SimpleBar style={{ maxHeight: maxHeight ? maxHeight : 'calc(100vh - 260px)' }} className="mt-3 rounded">

                                <table className="datatable table table-sm table-hover text-nowrap w-100">
                                    <thead>
                                        <tr>
                                            {
                                                columns.map(col => (
                                                    <th
                                                        onClick={() => { if (filters[col.key]?.isSortable) toggleSort(col.key) }}
                                                        className={classnames('text-wrap ' + 'tr-' + col.key, { 'c-pointer': filters[col.key]?.isSortable, 'd-none': col.isHidden,'font-sm': fontSm })}
                                                    >
                                                        {col.label}
                                                        <span>
                                                            {
                                                                (
                                                                    filters[col.key]?.isSortable
                                                                    && filters[col.key].sort == 'DESC'
                                                                    && <ArrowDownRight size={15} className="text-danger"></ArrowDownRight>
                                                                )
                                                            }
                                                            {
                                                                (
                                                                    filters[col.key]?.isSortable
                                                                    // && filters[column.id] == sortBy
                                                                    && filters[col.key].sort == 'ASC'
                                                                    && <ArrowUpRight size={15} className="text-success"></ArrowUpRight>
                                                                )
                                                            }
                                                            {
                                                                (
                                                                    filters[col.key]?.isSortable
                                                                    // && filters[column.id] != sortBy
                                                                    && !filters[col.key].sort
                                                                    && <Minus size={15}></Minus>
                                                                )
                                                            }
                                                        </span>
                                                    </th>


                                                ))
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {

                                            data.map((item, i) =>
                                                <tr
                                                    key={i}
                                                    onDoubleClick={() => { doDoubleClick(item) }}
                                                // onClick={() => {setSelectedRow(row.id)}}
                                                // className={classnames('d-cursor', { 'selected': selectedRow == row.id })}
                                                >

                                                    {
                                                        columns.map((col, i) => {
                                                            return (
                                                                <>
                                                                    {
                                                                        col.type == 'image' &&
                                                                        <td key={i} className={classnames({ 'd-none': col.isHidden })}>
                                                                            <img src={item[col.key]} className="modele-image" alt="Modele image" />
                                                                        </td>
                                                                    }
                                                                    {
                                                                        col.type != 'image' && !col.render && col.key != 'actions' &&
                                                                        <td key={i} className={classnames({ 'd-none': col.isHidden,'font-sm': fontSm })}>{item[col.key]}</td>
                                                                    }
                                                                    {
                                                                        col.type != 'image' && col.render && col.key != 'actions' &&
                                                                        <td key={i} className={classnames({ 'd-none': col.isHidden,'font-sm': fontSm })}>{col.render(item[col.key], item)}</td>
                                                                    }
                                                                    {
                                                                        col.type != 'image' && col.key == 'actions' &&
                                                                        <td key={i} className={classnames({ 'd-none': col.isHidden })}>
                                                                            <Actions currentRole={currentRole} row={toJS(item)} listActions={col.listActions}></Actions>
                                                                        </td>
                                                                    }
                                                                </>
                                                            )
                                                        }
                                                        )
                                                    }
                                                    {

                                                    }
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </table>
                            </SimpleBar>
                            <div className="row mx-0 mt-3 font-sm">
                                <div className="col-md-7 col-sm-12">
                                    <div className="row col align-items-center">
                                        <div className="col-auto px-0">
                                            <button onClick={(e) => { e.preventDefault(); pagination.onPaginationChange('prev'); }}
                                                disabled={(pagination.index) <= 1} className={btnClassName ? btnClassName : 'btn btn-sm btn-outline-primary'}>
                                                <ChevronLeft size={18}></ChevronLeft>
                                                <span className={classnames({ 'd-none': hidePaginationLabel })}>Préc.</span>
                                            </button>
                                        </div>
                                        <div className="col-auto d-flex align-items-center justify-content-center">
                                            <span className="mr-2">Page&nbsp;</span>
                                            <input
                                                type="number"
                                                ref={inputPageRef}
                                                className="page-input form-control form-control-sm"
                                                style={{ width: '60px',height : '28px' }}
                                                defaultValue={pagination.index}

                                                onKeyDown={e => {
                                                    if (e.key === 'Enter') {
                                                        const page = e.target.value ? Number(e.target.value) : 0
                                                        pagination.onPaginationChange('goto', page)
                                                    }
                                                }}
                                            />
                                            <span className="ml-2">&nbsp;sur {Math.ceil(pagination.total / pagination.offset)}</span>
                                        </div>
                                        <div className="col-auto px-0">
                                            <button onClick={(e) => { e.preventDefault(); pagination.onPaginationChange('next'); }}
                                                disabled={pagination.index == Math.ceil(pagination.total / pagination.offset)} className={btnClassName ? btnClassName : 'btn btn-sm btn-outline-primary'}>
                                                <span className={classnames({ 'd-none': hidePaginationLabel })}>Suiv.</span>
                                                <ChevronRight size={18}></ChevronRight>
                                            </button>
                                        </div>
                                        <div className="col-auto ml-1">
                                            <button onClick={(e) => { e.preventDefault(); pagination.onPaginationChange('goto', pagination.index); }} className={btnClassName ? btnClassName : 'btn btn-sm btn-outline-primary'}>
                                                <RefreshCw size={18}></RefreshCw>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                < div className="col-md-5 col-sm-12 d-flex justify-content-end">
                                    <div className="">
                                        Résultats {(pagination.index - 1) * pagination.offset + 1}
                                        -
                                        {pagination.index * pagination.offset}
                                        {' sur '} {pagination.total} {' éléments'}
                                    </div>
                                </div>

                            </div>
                        </>
                    }
                </>
            }
        </>
    )
})

const Actions = (props) => {
    const { listActions, row, currentRole } = props
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen(prevState => !prevState);

    const rootSelector = document.getElementById('root');
    const div = document.createElement("div")
    div.className = "h-0"
    let portalContainer = useRef(div).current

    const filteredListActions = listActions.filter(item => item.roles ? item.roles.includes(currentRole) : true)

    useEffect(() => {
        // let portalContainer = document.createElement("div");
        rootSelector.appendChild(portalContainer)
        return () => {
            rootSelector.removeChild(portalContainer)
        }
    }, [])

    return (
        <>
            {
                filteredListActions.length > 0 &&
                <Dropdown isOpen={dropdownOpen} toggle={toggle} portalContainer={portalContainer}>
                    <DropdownToggle
                        tag="span"
                        data-toggle="dropdown"
                        aria-expanded={dropdownOpen}
                    >
                        <MoreHorizontal></MoreHorizontal>
                    </DropdownToggle>
                    <Portal target={portalContainer}>
                        <DropdownMenu>
                            {
                                filteredListActions
                                    .map(item => <DropdownItem onClick={() => item.action(row)}>
                                        {item.name == 'activer' ? row.is_active > 0 ? 'Désactiver' : 'Activer' : item.label}
                                    </DropdownItem>)
                            }
                        </DropdownMenu>
                    </Portal>
                </Dropdown>
            }
        </>
    )
}

export const ColumnsToggle = (props) => {
    const { columns, className, toggleColumn } = props
    const [popoverOpen, setPopoverOpen] = useState(false);
    const toggle = () => setPopoverOpen(!popoverOpen);
    return (
        <div className="">
            <button id="btn-column" className="btn btn-sm btn-success">
                <FontAwesomeIcon icon={faColumns} className="mr-2"></FontAwesomeIcon>
                Colonnes</button>
            <Popover popperClassName="popover-columns" trigger="legacy" placement="bottom" target="btn-column"
                isOpen={popoverOpen} toggle={toggle}>
                <PopoverHeader>Affichage des colonnes</PopoverHeader>
                <PopoverBody>
                    <div className="row m-0">
                        {columns.filter(item => !item.isNotDisplay).map(column => (column.key != 'actions' && (
                            <div key={column.key} class="custom-control custom-checkbox  col-6">
                                <input id={column.key} onClick={() => toggleColumn(column)} checked={!column.isHidden} class="custom-control-input" type="checkbox" />
                                <label for={column.key} class="custom-control-label font-weight-normal pointer">{column.label}</label>
                            </div>
                        )))}
                    </div>
                </PopoverBody>
            </Popover>
        </div>
    )
}

export class DropdownPortal extends Dropdown {
    handleDocumentClick(e) {
        if (e && (e.which === 3 || (e.type === 'keyup' && e.which !== "Tab"))) return;
        const container = this.getContainer();

        const { portalContainer } = this.props;

        if (portalContainer.contains(e.target) && portalContainer !== e.target && (e.type !== 'keyup' || e.which === "Tab")) {
            return;
        }

        if (container.contains(e.target) && container !== e.target && (e.type !== 'keyup' || e.which === "Tab")) {
            return;
        }

        this.toggle(e);
    }
}

export class Portal extends React.Component {
    render() {
        const { children, target = document.body } = this.props;

        return createPortal(children, target);
    }
}


export default DataTableSimple
