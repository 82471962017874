import axios from 'axios'
import UserStore from '../stores/UserStore';
import modalAlert from '../components/Alert'
import LoadingStore from '../stores/LoadingStore';

const instance = axios.create({
    baseURL: process.env['REACT_APP_API_URL'],
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
});

const source = axios.CancelToken.source();

instance.interceptors.request.use(
    config => {
        const session = {access_token : window.access_token}
        if (session) {
            const user = session
            if (user.access_token) {
                config.data.access_token = user.access_token
            }
        }

        //Token cancel
        config.cancelToken = source.token
        return config;
    },
    error => {
        Promise.reject(error)
    }
);



let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    console.log('faildsd', failedQueue)
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    })

    failedQueue = [];
}

instance.interceptors.response.use(function (response) {
    return response;
}, async (error) => {

    const originalRequest = error.config;
    console.log(error)
    switch (error.response.status) {

        case 401:
            // alert('unauthenticated')
            // alert('error 419')
            if (isRefreshing) {
                return new Promise(function (resolve, reject) {
                    failedQueue.push({ resolve, reject })
                }).then(token => {
                    // originalRequest.data.access_token = token;
                    // let data = JSON.parse(originalRequest.data)
                    // data.access_token = token

                    originalRequest.data = JSON.parse(originalRequest.data)
                    originalRequest.data.access_token = token

                    return instance(originalRequest);
                }).catch(err => {
                    return Promise.reject(err);
                })
            }

            originalRequest._retry = true;
            isRefreshing = true;


            return new Promise(function (resolve, reject) {

                let session = localStorage.getItem(process.env['REACT_APP_LOCAL_STORAGE_KEY'])
                let headers = {}
                let user = {}
                if (window.client_id && window.client_secret) {
                    user = {cleint_id : window.client_id, client_secret : window.client_secret}
                }
                //{"access_token" : "de9f625bf9c1489a9e784e98ff8536d895ab0d7e", "client_id":"PANOR_TPE_VIRTUEL", "client_secret":"PANOR_TPE_VIRTUEL"}
                axios.post(process.env['REACT_APP_API_URL'], {
                    action: 'login',
                    client_id: user.client_id,
                    client_secret: user.client_secret,
                    grant_type: 'client_credentials'
                })
                    .then((res) => {
                        console.log(res)
                        user.access_token = res.data.access_token
                        user.action = 'login'
                        // localStorage.setItem(process.env['REACT_APP_LOCAL_STORAGE_KEY'], JSON.stringify(user));
                        if (window.ReactNativeWebView) { 
                            window.ReactNativeWebView.postMessage(JSON.stringify(user))
                        }

                        originalRequest.data = JSON.parse(originalRequest.data)
                        originalRequest.data.access_token = res.data.access_token
                        // originalRequest.data = JSON.stringify(data);

                        processQueue(null, res.data.access_token);

                        resolve(instance(originalRequest));
                    })
                    .catch(async (err) => {
                        failedQueue = [];
                        console.log(err)
                        // processQueue(err, null);
                        await modalAlert({
                            title: 'Info',
                            typeAlert: 'info',
                            text: "Votre session a expirée"
                        })

                        source.cancel()

                        // UserStore.logout('')
                        LoadingStore.reset()
                        // history.push('/login')
                        reject(err);
                    })
                    .then(() => { console.log('then'); isRefreshing = false })
            })


        default:
            modalAlert({
                title: 'Erreur',
                typeAlert: 'danger',
                text: "Serveur injoignable"
            })
            LoadingStore.reset()
            return Promise.reject(error);

    }

});



export default instance;