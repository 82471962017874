import { observable } from 'mobx'
import api from '../utils/api'
import modalAlert from '../components/Alert'
import LoadingStore from './LoadingStore'

const props = {
    user: {

    },
    successMobileRegister: false,
    emailToRegister: null,
    resetPasswordIsOk: null,
    accountActivated: null,
    loading: false
}

const UserStore = {

    props: props,

    /***** Methods ******/
    async init() {
        LoadingStore.on()
        console.log('init User')
        const session = {access_token : window.access_token}
        console.log(session)
        // console.log(window)
        if (session && session.access_token) {
            // this.props.user = session
            await this.whoami()
            LoadingStore.off()
            return true
            // window.user = this
            // CampagneStore.getAllStatutCampagne(session.idCompteSMS)
        } else {
            LoadingStore.off()
            return false
        }
    },
    async whoami() {
        console.log('whoami')
        LoadingStore.on()
        const res = await api.post('', { action: 'me' })
        if (res && res.data && res.data.success) {
            res.data.user.isAuthenticated = true
            this.props.user = res.data.user
            // console.log(res.data.user)
            // localStorage.setItem('fidlink_api_user', JSON.stringify(res.data.user))
        }
        else if (typeof res.data.success == 'boolean') {
            this.props.user = {}
        }


        LoadingStore.off()
    },
    async login(values) {
        LoadingStore.on()
        const res = await api.post('', values)
        if (res && res.data.success) {
            console.log('LOGIN_SUCCESS')
            console.log('store', process.env['REACT_APP_LOCAL_STORAGE_KEY'])
            // localStorage.setItem(process.env['REACT_APP_LOCAL_STORAGE_KEY'], JSON.stringify({
            //     access_token: res.data.access_token,
            //     client_id: values.client_id,
            //     client_secret: values.client_secret
            // }))

            window.access_token = res.data.access_token,
            window.client_id = values.client_id,
            window.client_secret = values.client_secret

            if (window.ReactNativeWebView) { // ensure window.ReactNativeWebView is there, otherwise, web app might crash if is not there
                window.ReactNativeWebView.postMessage(JSON.stringify({...values,access_token : res.data.access_token}))
            }

            await this.whoami()

            this.props.user.isAuthenticated = true

        }
        else if (typeof res.data.success == 'boolean') {
            modalAlert({
                title: 'Erreur',
                typeAlert: 'danger',
                text: res.data.msg
            })
        }
        LoadingStore.off()
    },
    async logout() {
        console.log('Logout user')
        localStorage.removeItem(process.env['REACT_APP_LOCAL_STORAGE_KEY'])
        this.reset()
    },
    isLoggedIn() {
        return this.props.user.isAuthenticated
    },
    isNotRestricted(roles) {
        if (roles)
            return !roles.includes(this.props.user.role_id)
        return true
    },
    reset() {
        this.props = props
    }
}

export default observable(UserStore)