import React, { useState } from "react";
import { observer } from "mobx-react";
import CardStore from "../stores/CardStore";
import myYup from "../utils/myYup";
import { useFormik } from "formik";
import Input from "../components/Input";
import { Check, CheckCircle } from "react-feather";
import modalPin from "../components/CodePin";
import { useParams } from "react-router-dom";


const RegisterPage = observer((props) => {

    const { idRes, idCom } = useParams()

    const [success, setSuccess] = useState(false)

    const {
        resetForm,
        values,
        setFieldValue,
        errors,
        handleSubmit,
        handleReset,
        validateForm,
        setErrors
    } = useFormik({
        initialValues: {
            NumCarte: '',
            TypeTransac: '',
            pme: '',
            MontantTransac: '',
            unitLabel: '',
            reseau_id: idRes,
            commercant_id: idCom
        },
        validateOnBlur: false,
        validateOnChange: false,
        validationSchema: myYup.object().shape({
            nom: myYup.string().required(),
            prenom: myYup.string().required(),
            email: myYup.string().email().required(),
            date_naiss: myYup.string().required(),
            tel: myYup.string().required().matches(/^[0-9]+$/, "Le numéro ne doit contenir que des chiffres")
                .min(10, 'Le numéro doit contenir 10 chiffres')
                .max(10, 'Le numéro doit contenir 10 chiffres'),
            cp: myYup.string().required().matches(/^[0-9]+$/, "Le numéro ne doit contenir que des chiffres"),
            ville: myYup.string().required(),
        }),
        onSubmit: async (values) => {

            // const code = await modalPin()
            // const isCorrect = await CardStore.checkPin(card, code)

            // if (!isCorrect)
            //     return
            const res = await CardStore.createNewCard(values, resetForm)
            if (res)
                setSucccess(true)
        },
    });

    return (
        <div className="login-page">
            <div className="login-box mt-5">
                <div className="card">
                    {
                        !success &&
                        <div className="card-body">
                            <h2 className="text-secondary">Création carte</h2>
                            <p>Veuillez compléter le formulaire ci-dessous afin de créer votre carte de fidélité</p>
                            <div className="row">
                                <div className="form-group col-sm-6 col-12 mb-2">
                                    <Input
                                        name="gender"
                                        id={"gender1"}
                                        label="Homme"
                                        type="radio"
                                        onChange={() => setFieldValue('gender', 1)}
                                        isChecked={values["gender"] == 1}
                                        value={1}
                                        className={"form-control"}
                                        error={errors["gender"]}
                                    />
                                </div>
                                <div className="form-group col-sm-6 col-12 mb-2">
                                    <Input
                                        name="gender"
                                        id={"gender2"}
                                        label="Femme"
                                        type="radio"
                                        onChange={() => setFieldValue('gender', 2)}
                                        isChecked={values["gender"] == 2}
                                        value={2}
                                        className={"form-control"}
                                        error={errors["gender"]}
                                    />
                                </div>
                                <div className="form-group floated col-12 mb-2">
                                    <label>Nom</label>
                                    <Input
                                        name="nom"
                                        type="text"
                                        onChange={setFieldValue}
                                        value={values["nom"]}
                                        className={"form-control"}
                                        error={errors["nom"]}
                                    />
                                </div>
                                <div className="form-group floated col-12 mb-2">
                                    <label>Prénom</label>
                                    <Input
                                        name="prenom"
                                        type="text"
                                        onChange={setFieldValue}
                                        value={values["prenom"]}
                                        className={"form-control"}
                                        error={errors["prenom"]}
                                    />
                                </div>
                                <div className="form-group floated col-12 mb-2">
                                    <label>Email</label>
                                    <Input
                                        name="email"
                                        type="email"
                                        onChange={setFieldValue}
                                        value={values["email"]}
                                        className={"form-control"}
                                        error={errors["email"]}
                                    />
                                </div>
                                <div className="form-group floated col-12 mb-2">
                                    <label>Date de naissance</label>
                                    <Input
                                        name="date_naiss"
                                        type="date"
                                        onChange={setFieldValue}
                                        value={values["date_naiss"]}
                                        className={"form-control"}
                                        error={errors["date_naiss"]}
                                    />
                                </div>
                                <div className="form-group floated col-12 mb-2">
                                    <label>N° Mobile</label>
                                    <Input
                                        name="tel"
                                        type="text"
                                        onChange={setFieldValue}
                                        value={values["tel"]}
                                        className={"form-control"}
                                        error={errors["tel"]}
                                    />
                                </div>
                                <div className="form-group floated col-12 mb-2">
                                    <label>Code postal</label>
                                    <Input
                                        name="cp"
                                        type="text"
                                        onChange={setFieldValue}
                                        value={values["cp"]}
                                        className={"form-control"}
                                        error={errors["cp"]}
                                    />
                                </div>
                                <div className="form-group floated col-12 mb-2">
                                    <label>Ville</label>
                                    <Input
                                        name="ville"
                                        type="text"
                                        onChange={setFieldValue}
                                        value={values["ville"]}
                                        className={"form-control"}
                                        error={errors["ville"]}
                                    />
                                </div>
                                <div className="w-100 d-flex justify-content-between mt-3">
                                    <button
                                        type="button"
                                        className="btn btn-secondary w-100"
                                        onClick={handleSubmit}
                                    >
                                        <span className="me-2">Valider</span>

                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        success &&
                        <div className="card-body d-flex flex-column ac">
                            <CheckCircle className="text-success" size={100}></CheckCircle>
                            <h4>Inscription réussie</h4>
                            <p>Un email avec les information relative à votre carte vous a été envoyé.</p>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
})

export default RegisterPage