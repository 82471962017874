import React, { useState, useEffect, useRef } from 'react'
import classnames from 'classnames'
import SimpleBar from 'simplebar-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment'
import DatePicker from 'react-datepicker'
import { Portal } from 'react-overlays'
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import fr from 'date-fns/locale/fr'
import { toJS } from 'mobx';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { CheckCircle } from 'react-feather';

registerLocale('fr', fr)

const Input = (props) => {



    const {
        input,
        id,
        sign,
        unite,
        icon,
        type,
        name,
        col,
        rows,
        isChecked,
        inverseChecked,
        className,
        placeholder,
        label,
        onChange,
        value,
        error,
        onKeyDown,
        highlight,
        readOnly,
        list,
        fetch,
        minDate,
        disabled,
        depend
    } = props

    const [show, setShow] = useState(false)

    if (type == 'text' || type == 'email') {
        return (
            <>
                {label && <label>{label}</label>}
                <input
                    name={name}
                    placeholder={placeholder}
                    onChange={(e) => onChange(name, e.target.value)}
                    value={value}
                    disabled={disabled}
                    autoComplete="true"
                    onKeyDown={onKeyDown}
                    className={className + classnames({ " is-invalid": (error || highlight) })}
                    type={type} />
                {
                    (error && <div className="invalid-feedback">{error}</div>)
                }

            </>
        )
    }

    if (type == 'montant_transac') {

        return (
            <>
                <div class="input-group mb-3">
                    <span class="input-group-text-alt">{sign}</span>
                    <input
                        name={name}
                        placeholder={placeholder}
                        onChange={(e) => onChange(name, e.target.value)}
                        value={value}
                        disabled={disabled}
                        autoComplete="true"
                        onKeyDown={onKeyDown}
                        className={className + classnames({ " is-invalid": (error || highlight) })}
                        min={1}
                        type={'number'}
                    />
                        <span class="input-group-text-alt">{unite}</span>

                </div>
            </>
        )
    }

    if (type == 'textarea') {
        return (
            <>
                {label && <label>{label}</label>}
                <textarea
                    name={name}
                    placeholder={placeholder}
                    onChange={(e) => onChange(name, e.target.value)}
                    value={value}
                    onKeyDown={onKeyDown}
                    rows={rows}
                    className={className + classnames({ " is-invalid": (error || highlight) })}>
                    {value}
                </textarea>
                {
                    (error && <div className="invalid-feedback">{error}</div>)
                }

            </>
        )
    }

    if (type == 'password') {
        return (
            <>
                {label && <label>{label}</label>}
                <input
                    name={name}
                    autoComplete="true"
                    placeholder={placeholder}
                    onChange={(e) => onChange(name, e.target.value)}
                    value={value}
                    onKeyDown={onKeyDown}
                    className={className + classnames({ " is-invalid": (error || highlight) })}
                    type={type} />
                {
                    (error && <div className="invalid-feedback">{error}</div>)
                }

            </>
        )
    }

    if (type == 'switch') {
        console.log(name, inverseChecked, inverseChecked ? parseInt(value) <= 0 : parseInt(value) > 0)
        return (
            <>
                <div className="custom-control custom-switch">
                    <input
                        type="checkbox"
                        name={name}
                        checked={inverseChecked ? parseInt(value) <= 0 : parseInt(value) > 0}
                        onChange={(e) => { onChange(name, parseInt(value) > 0 ? 0 : 1) }}
                        className="custom-control-input"
                        id={label + '_switch'} />
                    <label className="custom-control-label" for={label + '_switch'}>{label}</label>
                </div>

            </>
        )
    }

    if (type == 'checkbox') {
        return (
            <>
                <div className="custom-control custom-checkbox">
                    <input id={name} name={name} checked={value > 0}
                        onChange={(e) => onChange(name, e.target.checked ? 1 : 0)}
                        className="custom-control-input" type="checkbox" />
                    <label for={name} className="custom-control-label pointer">{label}</label>
                </div>
            </>
        )
    }

    if (type == 'select') {
        return (
            <>
                {label && <label>{label}</label>}
                <select
                    name={name}
                    onChange={(e) => onChange(name, e.target.value)}
                    value={value}
                    readOnly={readOnly}
                    className={className + classnames({ " is-invalid": (error || highlight) })}>
                    {
                        (list && list.map((item) => <option value={item.value}>{item.label}</option>))
                    }
                </select>
                {
                    (error && <div className="invalid-feedback">{error}</div>)
                }

            </>
        )

    }

    if (type == 'mySelect') {



        return (
            <>
                {label && <label>{label}</label>}
                <SimpleSelect
                    async={true}
                    fetch={fetch}
                    value={value}
                    error={error}
                    depend={depend}
                    list={list}
                    className={className + classnames({ " is-invalid": (error || highlight) })}
                    onChange={(val) => { onChange(name, val) }}
                ></SimpleSelect>

            </>
        )

    }

    if (type == 'radio') {
        return (
            <div className={"form-check"}>
                <input id={id} name={name} checked={isChecked}
                    onChange={(name, val) => onChange(name, val)}
                    className="form-check-input" type="radio" />
                <label for={id} className="form-check-label pointer">
                    {label}
                </label>
            </div>
        )

    }

    if (type == 'date') {

        let valueInput = null
        let valueDatePicker = null

        if (moment(value).isValid()) {
            valueDatePicker = moment(value).toDate()
            valueInput = moment(value).format('YYYY-MM-DD')
        }

        const onChangeDate = (date) => {
            return onChange(name, moment(date).format('YYYY-MM-DD'))
        }

        return (
            <>
                <input
                    type="hidden"
                    placeholder={placeholder}
                    value={valueInput}
                    name={name}>

                </input>
                {label && <label>{label}</label>}
                <DatePicker
                    // popperContainer={CalendarContainer}
                    selected={valueDatePicker}
                    onChange={date => onChangeDate(date)}
                    locale="fr"
                    minDate={minDate}
                    dateFormat="dd/MM/yyyy"
                    className={className + classnames({ " is-invalid": (error || highlight) })}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                />
                {
                    (error && <div className="invalid-feedback">{error}</div>)
                }

            </>
        )
    }

    if (type == 'checkboxes') {
        const multipleOnChange = (val) => {
            // console.log('onChangeMultiple INIT', value, list)
            console.log('onChangeMultiple', name, val, toJS(value))
            if (val != 'all') {
                let position = value.findIndex(o => o == val);
                console.log('position :', position)
                if (position === -1)
                    onChange(name, [...value, val])
                else
                    onChange(name, [...value.filter(item => item != val)])
            } else {
                if (list.length != value.length) {
                    onChange(name, [...list.map(item => item.id)])
                } else {
                    onChange(name, [])
                }
            }

        }

        return (
            <>
                <div className={"custom-control custom-checkbox col-12"}>
                    <input id={name + '_all'} name={name + '_all'} checked={value.length == list.length}
                        onChange={() => multipleOnChange('all')}
                        className="custom-control-input" type="checkbox" />
                    <label for={name + '_all'} className="custom-control-label pointer">
                        {(value.length == list.length) ? 'Tout  désélectionner' : 'Tout sélectionner'}
                    </label>
                </div>
                {list && list.map(item => (

                    <div className={"custom-control custom-checkbox " + className}>
                        <input id={name + '_' + item.id} name={name + '_' + item.id} checked={value.includes(item.id)}
                            onChange={() => multipleOnChange(item.id)}
                            className="custom-control-input" type="checkbox" />
                        <label for={name + '_' + item.id} className="custom-control-label pointer">{item.label}</label>
                    </div>
                ))}

                <div className="col-12">
                    {
                        (error && <div className="invalid-feedback d-block">{error}</div>)
                    }
                </div>
            </>
        )


    }

    if (type == 'multiple_choice') {
        const multipleOnChange = (val) => {
            // console.log('onChangeMultiple INIT', value, list)
            console.log('onChangeMultiple', name, val, toJS(value))
            if (val != 'all') {
                let position = value.findIndex(o => o == val);
                console.log('position :', position)
                if (position === -1)
                    onChange(name, [...value, val])
                else
                    onChange(name, [...value.filter(item => item != val)])
            } else {
                if (list.length != value.length) {
                    onChange(name, [...list.map(item => item.id)])
                } else {
                    onChange(name, [])
                }
            }

        }

        return (
            <>
                <div className="col-12">
                    {
                        (error && <div className="invalid-feedback d-block">{error}</div>)
                    }
                </div>
                <div className={"custom-control custom-checkbox col-12"}>
                    <input id={name + '_all'} name={name + '_all'} checked={value.length == list.length}
                        onChange={() => multipleOnChange('all')}
                        className="custom-control-input" type="checkbox" />
                    <label for={name + '_all'} className="custom-control-label pointer font-weight-normal">
                        {(value.length == list.length) ? 'Tout  désélectionner' : 'Tout sélectionner'}
                    </label>
                </div>
                <div className="col-12 mt-3">
                    <div className="row">


                        {list && list.map(item => (
                            <div className={col ? col : 'col-md-3'}>

                                <div className={classnames("card-shop mb-0 pt-2 d-flex flex-column ac", { "selected": value.includes(item.id) })}
                                    onClick={() => multipleOnChange(item.id)}>
                                    {
                                        value.includes(item.id) &&
                                        <CheckCircle size={30} className={"text-orange mr-2 check-icon"}></CheckCircle>
                                    }
                                    <div className="my-1">
                                        {icon}
                                    </div>
                                    <span className="h4 font-weight-bold text-old">

                                        {item.label}
                                    </span>

                                </div>
                            </div>
                        ))}
                    </div>
                </div>

            </>
        )


    }

}

export default Input

const CalendarContainer = ({ children }) => {
    const el = document.getElementById('calendar-portal')

    return (
        <Portal container={el}>
            {children}
        </Portal>
    )
}

const SimpleSelect = (props) => {
    const { list, value, async, fetch, onChange, className, error, depend, setFie } = props
    const [search, setSearch] = useState(null)
    const [options, setOptions] = useState(list ? list : [])
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)

    const selectRef = useRef()
    // console.log(options)
    const doSearch = async (search) => {
        setSearch(search)
        if (async) {
            // console.log('async',typeof fetch == 'function')
            setLoading(true)
            if (typeof fetch == 'function')
                setOptions(await fetch(search, depend))
            setLoading(false)
        } else {

            if (search.length)
                setOptions(options.filter(item => item.name.contains(search)))
            else
                setOptions(options)
        }
    }

    const onSelect = async (val) => {
        onChange(val)
        setOpen(false)
    }

    useEffect(() => {
        // onChange({})
        console.log('change')
        doSearch()
    }, [depend]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (selectRef.current && !selectRef.current.contains(event.target)) {
                setOpen(false)
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [selectRef]);

    return (
        <>
            <div ref={selectRef} className="multiselect">
                <div className={"multiselect-input " + classnames(className, { 'focused': open })} onClick={() => setOpen(!open)}>{value?.name}</div>
                {
                    open &&
                    <div className="multiselect-list w-100 rounded">
                        {
                            !list &&
                            <>
                                < div className="p-2">
                                    <input type="text" placeholder="Recherche ..." className="form-control form-control-sm" value={search} onChange={(e) => doSearch(e.target.value)} />
                                </div>
                            </>
                        }
                        {
                            !loading &&
                            <SimpleBar style={{ maxHeight: '360px' }} className="rounded">
                                <ul className="multiselect-options-list" style={{ maxHeight: '360px' }}>
                                    {options.length > 0 &&
                                        options.map((item, index) =>
                                            <li
                                                key={index}
                                                className="multiselect-option d-flex ac"
                                                onClick={() => onSelect(item)}
                                            >
                                                {item.name}
                                            </li>
                                        )
                                    }
                                    {
                                        options.length == 0 &&
                                        <li className="text-center">Aucun résultat</li>
                                    }
                                </ul>
                            </SimpleBar>
                        }
                        {
                            loading &&
                            <div className="w-100 text-center py-3">
                                <FontAwesomeIcon icon={faSpinner} size={"lg"} className="fa-spin text-orange mr-2"></FontAwesomeIcon>
                                <span>Chargement ...</span>
                            </div>
                        }
                    </div>
                }
                {
                    (error && <div className="invalid-feedback">{error}</div>)

                }
            </div>
        </>
    )
}