import React from 'react'
import LoadingStore from '../stores/LoadingStore'
import { observer } from 'mobx-react'

const PinPad = observer((props) => {

    const { handleSubmit, setValue, value, enableZeroFirst, maxLength } = props

    const onPress = (val) => {

        if (val == 'correct')
            return setValue(value.substring(0, value.length - 1))
        if (val == 'erase')
            return setValue('')
        if (!!maxLength > 0 && maxLength < (value.length + 1))
            return

        if (parseFloat(value) == 0 && !enableZeroFirst)
            setValue("" + val)
        else
            setValue("" + value + val)

        console.log(value)
    }

    return (

        <div className="pinpad-wrapper">
            <button onClick={() => onPress(1)} className='pinpad-btn'>1</button>
            <button onClick={() => onPress(2)} className='pinpad-btn'>2</button>
            <button onClick={() => onPress(3)} className='pinpad-btn'>3</button>
            <button onClick={() => onPress(4)} className='pinpad-btn'>4</button>
            <button onClick={() => onPress(5)} className='pinpad-btn'>5</button>
            <button onClick={() => onPress(6)} className='pinpad-btn'>6</button>
            <button onClick={() => onPress(7)} className='pinpad-btn'>7</button>
            <button onClick={() => onPress(8)} className='pinpad-btn'>8</button>
            <button onClick={() => onPress(9)} className='pinpad-btn'>9</button>
            <button onClick={() => onPress('')} className='pinpad-btn'>F</button>
            <button onClick={() => onPress(0)} className='pinpad-btn'>0</button>
            <button onClick={() => onPress(',')} className='pinpad-btn'>,</button>
            <button onClick={() => onPress('erase')} className='pinpad-btn erase'>Annuler</button>
            <button onClick={() => onPress('correct')} className='pinpad-btn correct'>Corriger</button>
            <button onClick={() => handleSubmit()} className='pinpad-btn success'>Valider</button>
        </div>

    )
})

export default PinPad