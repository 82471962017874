import React from 'react';
import ReactDOM from 'react-dom/client';
import AppRoute from './components/AppRoute'
import Loading from './components/Loading';
import { BrowserRouter as Router } from "react-router-dom";
import './assets/css/style.scss'

const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
  <>
    <Loading></Loading>

    <Router>
      <AppRoute></AppRoute>
    </Router>   
  </>
);
