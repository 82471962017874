
import { Modal, ModalBody } from "reactstrap";
import myYup from "../utils/myYup";
import { CheckCircle, X } from "react-feather";
import CardStore from "../stores/CardStore";
import { useFormik } from "formik";
import { formatPhoneNumber, getEquivalence } from "../utils/utils";
import PinPad from "../components/PinPad";
import UserStore from "../stores/UserStore";
import { useState } from "react";
import classNames from "classnames";
import { useEffect } from "react";
import modalAlert from "../components/Alert";


const StepBar = (props) => {

    const { steps, active, setStep } = props
    const [visited, setVisited] = useState([])

    useEffect(() => {
        if (!visited.includes(active)) {
            setVisited([...visited, active])
        }
    }, [active])

    const changeStep = (val) => {
        if (visited.includes(val) && active > val) {
            setStep(val)
        }
    }

    return (
        <ul class="step-bar nav nav-pills">
            {
                steps.map((item, index) =>
                    <li class={classNames("step-bar__item", { "active": active == index })} style={{ width: (98 / steps.length) + '%' }}>
                        <a class="step-bar__link" onClick={() => changeStep(index)}>
                            <span class="step">{index + 1}</span>
                            <span class="desc">{item}</span>
                        </a>
                    </li>
                )
            }
        </ul>
    )
}

const CreateCardModal = (props) => {
    const { isOpenCreateCardModal, setIsOpenCreateCardModal } = props;

    const {
        values,
        setFieldValue,
        errors,
        handleSubmit,
    } = useFormik({
        initialValues: {
            tel: '',
            MontantTransac: 0,
            TypeTransac: UserStore.props.user.defautTypeTransac
        },
        validateOnBlur: false,
        validateOnChange: false,
        validationSchema: myYup.object().shape({
            tel: myYup.string().required().matches(/^[0-9]+$/, "Le numéro ne doit contenir que des chiffres")
                .min(10, 'Le numéro doit contenir 10 chiffres')
                .max(10, 'Le numéro doit contenir 10 chiffres'),
            MontantTransac: myYup.string().test("MontantTransac", "Ce champs est requis", (val) => parseFloat(val) > 0 ? true : false),
        }),
        onSubmit: (values) => { setIsOpenCreateCardModal(false); CardStore.sendSmsCreateCard(values['tel'],values['MontantTransac']) },
    });

    const telSchema = myYup.object().shape({
        tel: myYup.string()
            .required("Le numéro de téléphone du client est requis")
            .matches(/^[0-9]+$/, "Le numéro ne doit contenir que des chiffres")
            .min(10, 'Le numéro doit contenir 10 chiffres')
            .max(10, 'Le numéro doit contenir 10 chiffres')
    })

    const montantSchema = myYup.object().shape({
        MontantTransac: myYup.string().test("MontantTransac", "Le montant initial est requis", (val) => parseFloat(val) > 0 ? true : false)
    })

    const getSign = (typeTransac) => {
        if (!values['TypeTransac'])
            return ''
        if (values['TypeTransac'] && [1, 3, 5].includes(values['TypeTransac']))
            return '+'
        else
            return '-'
    };
    const steps = ['N° téléphone client', 'Montant initial']
    const [currStep, setCurrStep] = useState(0)

    const submitCreateCard = async () => {

        try {
            const validation = await telSchema.validate({ tel: values['tel'] })
        }
        catch (err) {
            // console.log(err.message)
            return modalAlert({
                title: 'Attention',
                text: err.message ? err.message : '',
                typeAlert: 'warning'
            })
        }

        if (steps.length > 1) {
            setCurrStep(currStep + 1)
        }
        else {

            handleSubmit()
        }

    }

    const submitMontant = async () => {

        try {
            const validation = await montantSchema.validate({ MontantTransac: values['MontantTransac'] })
        }
        catch (err) {
            // console.log(err.message)
            return modalAlert({
                title: 'Attention',
                text: err.message ? err.message : '',
                typeAlert: 'warning'
            })
        }

        handleSubmit()


    }

    return (
        <Modal isOpen={isOpenCreateCardModal} centered={true}>
            {isOpenCreateCardModal && (
                <ModalBody>
                    <h5>Nouvelle carte</h5>
                    <button
                        className="close-circle"
                        onClick={() => setIsOpenCreateCardModal(false)}
                    >
                        <X size={25}></X>
                    </button>
                    <div className="row">
                        <div className="col-12 mb-2">
                            <StepBar steps={steps} active={currStep} setStep={(val) => setCurrStep(val)}></StepBar>
                        </div>
                    </div>
                    {
                        currStep == 0 &&
                        <>
                            <div className="row">
                                <div className="form-group floated col-12 mb-2">
                                    <p>Veuillez saisir le n° de téléphone du client.</p>
                                    <p>Un lien de création de carte de fidélité lui sera envoyé.</p>
                                </div>
                            </div>
                            <div className="row">
                                <label className={classNames("h5 mb-0", { 'text-success': values["tel"].length >= 10 })}>N° de téléphone client</label>
                                <div className="form-group floated col-12 mb-2">
                                    <div className={classNames("montant-transac d-flex flex-column position-relative", { 'border-success': values["tel"].length >= 10 })} style={{ height: '70px' }}>

                                        <div className="d-flex ac">
                                            <span className="montant-transac-value">{formatPhoneNumber(values["tel"])}</span>
                                        </div>
                                    </div>
                                    {!!errors['tel'] && <div className="invalid-feedback d-block fw-bold">{errors['tel']}</div>}
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group floated col-12 mb-2 mt-3">

                                    <PinPad
                                        setValue={(val) => setFieldValue('tel', val)}
                                        maxLength={10}
                                        enableZeroFirst={true}
                                        value={values['tel']}
                                        errors={errors['tel']}
                                        handleSubmit={() => submitCreateCard()}
                                    ></PinPad>

                                </div>
                            </div>
                        </>
                    }
                    {
                        currStep == 1 &&
                        <>
                            <div className="row">
                                <div className="form-group floated col-12 mb-2">
                                    <p>Veuillez saisir le montant initial de la carte du client.</p>

                                </div>
                            </div>
                            <div className="row">

                                <div className="form-group col-12 mb-2">

                                    <label className={classNames("h5 mb-0", {})}>Montant initial</label>
                                    <div className={classNames("montant-transac d-flex flex-column")} style={{ height: '70px' }}>
                                        <div className="d-flex ac">
                                            <span className="montant-transac-sign me-2">{getSign()}</span>
                                            <span className="montant-transac-value" style={{ fontSize: '2.5rem', lineHeight: '10px' }}>{values["MontantTransac"]}</span>
                                            <span className="ms-2 montant-transac-unit">{values['unitLabel']}</span>
                                        </div>
                                        <div className="d-flex ac">
                                            {getEquivalence(
                                                values["MontantTransac"],
                                                UserStore.props.user.defautTypeTransac,
                                                UserStore.props.user
                                            )}
                                        </div>
                                    </div>
                                    {
                                        (errors['MontantTransac'] && <div className="invalid-feedback d-block">{errors['MontantTransac']}</div>)
                                    }
                                </div>
                                <PinPad
                                    setValue={(val) => setFieldValue('MontantTransac', val)}
                                    maxLength={10}
                                    value={values['MontantTransac']}
                                    errors={errors['MontantTransac']}
                                    handleSubmit={() => submitMontant()}
                                ></PinPad>
                            </div>
                        </>
                    }
                </ModalBody>
            )}
        </Modal>
    );
};

export default CreateCardModal