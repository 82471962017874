
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useFormikWizard } from "formik-wizard-form";
import myYup from "../utils/myYup";
import classNames from "classnames";
import { X, Check, ArrowLeft, ArrowRight } from "react-feather";
import Input from "../components/Input";
import { isInteger, useFormik } from "formik";
import { formatCardNumber, getUnitLabel } from "../utils/utils";
import AnimateHeight from 'react-animate-height';
import CardStore from "../stores/CardStore";
import { number } from "../utils/numeral";
import moment from "moment/moment";
import DataTableSimple from "../components/DatatableSimple";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQrcode } from "@fortawesome/free-solid-svg-icons";
import ScanModal from "../components/ScanModal";
import UserStore from "../stores/UserStore";



const SettingsModal = (props) => {
    const { isOpenSettingsModal, setIsOpenSettingsModal, } = props;

    const doLogout = () => {
        UserStore.logout()
    }

    return (
        <>
            <Modal isOpen={isOpenSettingsModal} size={'xl'}  centered={true}>
                {isOpenSettingsModal && (
                    <ModalBody>
                        <h5>Paramètres</h5>
                        <div className="d-flex flex-column">
                            <fieldset className="border rounded px-3 py-2">
                                <button className="close-circle" onClick={() => setIsOpenSettingsModal(false)}>
                                    <X size={25}></X>
                                </button>
                                <legend>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6 text-primary">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                                    </svg>

                                    <span className="h5 text-secondary">Compte</span>
                                </legend>
                                <div className="row">
                                    <div className="col-12">
                                        <button className="btn btn-secondary" onClick={() => doLogout()}>Se déconnecter</button>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </ModalBody>
                )}
            </Modal>
        </>
    );
};

export default SettingsModal