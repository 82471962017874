
import { Modal, ModalBody } from "reactstrap";
import { X } from "react-feather";
import { formatCardNumber } from "../utils/utils";
import CardStore from "../stores/CardStore";
import { number } from "../utils/numeral";
import DataTableSimple from "../components/DatatableSimple";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQrcode } from "@fortawesome/free-solid-svg-icons";
import ScanModal from "../components/ScanModal";
import UserStore from "../stores/UserStore";



const TransacModal = (props) => {
    const {
        getTransacs,
        data,
        columns,
        filters,
        pagination,
        numCarte,
        setNumCarte,
        init,
        isOpenTransacModal,
        setIsOpenTransacModal,
        fetchReseaux,
        fetchCommercants
    } = props;

    const [isOpenScanModal, setIsOpenScanModal] = useState(false)

    const doSearch = (card) => {
        CardStore.setNumCarte(card)
        CardStore.getTransacs()
    }

    useEffect(() => {
        init()
    }, [])

    return (
        <>
            <Modal isOpen={isOpenTransacModal} size={'xl'}  centered={true}>
                {isOpenTransacModal && (
                    <ModalBody>
                        <h5>Liste transactions</h5>
                        <button className="close-circle" onClick={() => setIsOpenTransacModal(false)}>
                            <X size={25}></X>
                        </button>
                        <div className="form-group d-flex col-12 ac">
                            <button type="button" className="btn btn-primary mx-1" onClick={() => setIsOpenScanModal(true)}>
                                <FontAwesomeIcon icon={faQrcode} size={'lg'} ></FontAwesomeIcon>
                                <span className="ms-2">Scanner une carte</span>
                            </button>
                        </div>
                        {
                            !!CardStore.props.NumCarte &&
                            <div className="form-group">
                                <div className="grid-infos-transac mt-3">
                                    <div className="border-bottom mb-0 ps-1 text-primary">N° de carte</div>
                                    <div className="border-bottom text-secondary ps-3  fw-bold">{formatCardNumber(CardStore.props.NumCarte)}</div>

                                    <div className="border-bottom mb-0 ps-1 text-primary">Solde {UserStore.props.user.NomUnitesPME1} : </div>
                                    <div className="border-bottom text-secondary ps-3  fw-bold">{number(CardStore.props.soldePme1)}</div>

                                    <div className="border-bottom mb-0 ps-1 text-primary">Nom client : </div>
                                    <div className="border-bottom text-secondary ps-3  fw-bold">{CardStore.props.clientNom}</div>

                                    <div className="border-bottom mb-0 ps-1 text-primary">Prénom client : </div>
                                    <div className="border-bottom text-secondary ps-3  fw-bold">{CardStore.props.clientPrenom}</div>

                                    <div className="border-bottom mb-0 ps-1 text-primary">Solde {UserStore.props.user.NomUnitesPME2} : </div>
                                    <div className="border-bottom text-secondary ps-3  fw-bold">{number(CardStore.props.soldePme2)}</div>
                                </div>
                            </div>
                        }
                        <div className="d-flex flex-column">
                            <DataTableSimple
                                columns={columns}
                                hideColumns={true}
                                hideFilters={true}
                                fontSm={true}
                                data={data}
                                filters={filters}
                                pagination={pagination}
                            ></DataTableSimple>
                        </div>

                    </ModalBody>
                )}
            </Modal>
            <ScanModal
                isOpenScanModal={isOpenScanModal}
                setIsOpenScanModal={setIsOpenScanModal}
                handleSubmit={(card) => doSearch(card)}
            ></ScanModal>
        </>
    );
};

export default TransacModal