import React, { useState } from 'react'
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { observer } from 'mobx-react'
import { Modal, ModalBody } from 'reactstrap'
import scanLogo from "../assets/img/qr-scan.png";
import { X } from "react-feather";

const ScanModal = observer((props) => {
    const {
        isOpenScanModal,
        handleSubmit,
        setIsOpenScanModal
    } = props

    const [stopScan,setStopScan] = useState(false)

    const onErrorScan = (error) => {
        if (error.name === "NotAllowedError") {
            alert('Vous devez autoriser la caméra')
        }
    };

    const onUpdateScan = (err,result) => {
        console.log('Scan Active')
        try{
            if(result){
                setStopScan(true)
                setIsOpenScanModal(false)
                console.log(result)
                let data = JSON.parse(result.text)
                let card = data.card
                console.log('onUpdateCard',card)
                handleSubmit(card)
            }
        }
        catch(err){
            console.log(err)
            alert('Une erreur est survenue')
        }
    };

    return (
        <Modal isOpen={isOpenScanModal} centered={true}>
            <ModalBody>
                <h5>Scan</h5>
                <button className="close-circle" onClick={() => setIsOpenScanModal(false)}>
                    <X size={25}></X>
                </button>
                <div className="row">
                    <div className="col-12">
                        <div className="d-flex">
                            <img
                                src={scanLogo}
                                alt="scan-logo"
                                className="scan-logo"
                            />
                            <h4>Veuillez scanner la carte du client</h4>
                        </div>
                    </div>
                    <div className="col-12">
                        <BarcodeScannerComponent
                            onUpdate={onUpdateScan}
                            onError={onErrorScan}
                            stopStream={stopScan}
                        />
                    </div>
                </div>
            </ModalBody>
        </Modal>
    )
})

export default ScanModal