import React, { useState } from 'react'
import { Modal, ModalBody } from 'reactstrap'
import { unmountComponentAtNode, render } from 'react-dom';
import parse from 'html-react-parser';

const PromptPin = (props) => {

    const { title, text, close } = props

    const [value, setValue] = useState("");
    const [error, setError] = useState(false);
    const [open, setOpen] = useState(true);


    const handleClick = () => {
        setOpen(false)
        close(value)
    }

    const onPress = (val) => {
        if (val == 'correct') {
            if (value.length > 0)
                setValue(value.substring(0, value.length - 1))
            else
                console.log('not enought', value, " length : " + value.length)
        }
        else if (val == 'erase')
            setValue('')
        else
            if (value.length < 4)
                setValue("" + value + val)
            else
                console.log('too much', value, " length : " + value.length)
    }

    return (
        <Modal isOpen={open} backdrop="static" zIndex={1000000000000} centered={true} toggle={() => setOpen(!open)}>
            <ModalBody>
                <h5>
                    Saisie PIN
                </h5>
                <div className="row">
                    <div className="col">
                        <div className="form-group">
                            <p>Veuillez saisir votre code PIN à 4 chiffres : </p>
                        </div>
                    </div>
                </div>
                <form className="row mb-4">
                    <div className="col d-flex jc">
                        <div className="otp-input">{value[0]}</div>
                        <div className="otp-input">{value[1]}</div>
                        <div className="otp-input">{value[2]}</div>
                        <div className="otp-input">{value[3]}</div>
                    </div>
                </form>
                <div className="col">
                    <div className="pinpad-wrapper">
                        <button onClick={() => onPress(1)} className='pinpad-btn'>1</button>
                        <button onClick={() => onPress(2)} className='pinpad-btn'>2</button>
                        <button onClick={() => onPress(3)} className='pinpad-btn'>3</button>
                        <button onClick={() => onPress(4)} className='pinpad-btn'>4</button>
                        <button onClick={() => onPress(5)} className='pinpad-btn'>5</button>
                        <button onClick={() => onPress(6)} className='pinpad-btn'>6</button>
                        <button onClick={() => onPress(7)} className='pinpad-btn'>7</button>
                        <button onClick={() => onPress(8)} className='pinpad-btn'>8</button>
                        <button onClick={() => onPress(9)} className='pinpad-btn'>9</button>
                        <button onClick={() => onPress('')} className='pinpad-btn'>F</button>
                        <button onClick={() => onPress(0)} className='pinpad-btn'>0</button>
                        <button onClick={() => onPress(',')} className='pinpad-btn'>,</button>
                        <button onClick={() => {setValue('');setOpen(false)}} className='pinpad-btn erase'>Annuler</button>
                        <button onClick={() => onPress('correct')} className='pinpad-btn correct'>Corriger</button>
                        <button onClick={() => handleClick()} className='pinpad-btn success'>Valider</button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    )
}

const modalPin = (props) => {
    return new Promise(resolve => {
        let el = document.createElement('div');

        const handleResolve = result => {
            unmountComponentAtNode(el);
            el = null;
            resolve(result);
        };

        render(<PromptPin {...props} close={handleResolve} />, el);
    });
};

export default modalPin