import React from "react";
import { NavLink } from "react-router-dom";
import logo_fidlink from "../assets/img/logo_fidlink_blanc.png";
import logo from "../assets/img/bank-terminal.png";

const NavbarLayout = (props) => {
  const { children } = props;

  return (
    <div className="layout-top-nav">
      <div className="wrapper">
        <nav
          className="navbar navbar-expand-lg navbar-light"
          style={{ background: "transparent" }}
        >
          <div className="container">
            <div className="d-flex jc ac">
              <img
                src={logo}
                alt=""
                height="40"
                className="d-inline-block align-text-top"
              />
              <h3 className="text-white" style={{ fontWeight: 800 }}>TPE Virtuel</h3>
            </div>
            <a className="navbar-brand  text-white font-weight-bold d-flex ac jc" href="#">
              <img
                src={logo_fidlink}
                alt=""
                height="40"
                className=""
              />
            </a>

          </div>
        </nav>

        <div className="container pt-3">{children}</div>
      </div>
    </div>
  );
};

export default NavbarLayout;
