
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useFormikWizard } from "formik-wizard-form";
import myYup from "../utils/myYup";
import classNames from "classnames";
import { X, Check, ArrowLeft, ArrowRight, Feather, CheckCircle, Gift } from "react-feather";
import Input from "../components/Input";
import { useFormik } from "formik";
import { formatCardNumber, getEquivalence, getUnitLabel } from "../utils/utils";
import AnimateHeight from 'react-animate-height';
import CardStore from "../stores/CardStore";
import { number } from "../utils/numeral";
import moment from "moment/moment";
import { useEffect, useState } from "react";
import UserStore from "../stores/UserStore";
import LoadingStore from "../stores/LoadingStore";
import PinPad from "../components/PinPad";
import ScanModal from "../components/ScanModal";
import modalAlert from "../components/Alert";
import modalPin from "../components/CodePin";
import winAudio from "../assets/audios/win.mp3"
import Confetti from 'react-confetti'
import ScanAllModal from "../components/ScanAllModal";

export const SuccessModal = (props) => {

    const playAudio = () => {
        setTimeout(() => new Audio(winAudio).play(), 1000)
    }

    const data = {
        "libelleTransac": "Crédit points",
        "libelleSoldePrecendent": "Solde précédent",
        "libelleNouveauSolde": "Nouveau solde",
        "phraseTransac": "Points cr\u00e9dit\u00e9s",
        "montantTransac": 200,
        "enseigne": "TEST",
        "code": "450-697-6",

        "success": true,
        "card": "99999999087664594",
        "typeFid": "FIDELITE_COUPONS",
        "ModeFonctionnement": "3",
        "type": "credit",

        "conversionPME1": null,
        "conversionPME2": "1",
        "pme": 1,

        "dateTransac": "2022-11-16",
        "heureTransac": "17:00:17",
        "phraseSpot": null,
        "NomUnitePME1": "Point",
        "NomUnitesPME1": "Points",
        "NomUnitePME2": "Euro",
        "NomUnitesPME2": "Euros",
        "Spot": false, "SpotSeuil": 0,
        "SpotLeft": 38600,
        "DebitHitPME1": 0,
        "GainHitPME2": 0,
        "msg": "Le compte a bien \u00e9t\u00e9 cr\u00e9dit\u00e9",
        "AncienPME1": 1200, "NouveauPME1": 1400,
        "AncienPME2": 66,
        "NouveauPME2": 66,
        "AncienDateValidPME1": "2023-05-15",
        "AncienDateValidPME2": "2023-05-15",
        "NouveauDateValidPME1": "2023-05-15",
        "NouveauDateValidPME2": "2023-05-15"
    }


    return (
        <div className="d-flex flex-column mb-3">
            {
                props.data.Spot &&

                <Confetti
                    width={500}
                    height={700}
                />
            }
            <h4 className="mb-0 text-secondary">Récapitulatif</h4>
            <div className="d-flex flex-column ac">
                <CheckCircle size={40} className="text-success"></CheckCircle>
                <h5 className="text-success">Transaction effectuée</h5>
                {
                    props.data.Spot && props.data.ModeFonctionnement == 3 &&
                    <div className="border-top py-3 border-bottom d-flex flex-column ac">
                        <Gift size={60} className="text-primary"></Gift>
                        <h3 className="text-primary">Bravo ! Vous avez atteint le SPOT</h3>
                        {playAudio()}
                    </div>
                }
                {
                    props.data.Spot && props.data.ModeFonctionnement == 1 &&
                    <div className="border-top py-3 border-bottom d-flex flex-column ac">
                        <Gift size={60} className="text-primary"></Gift>
                        <h3 className="text-primary">Bravo ! Vous avez atteint le SPOT n° {props.data.SpotSeuil}</h3>
                        {playAudio()}
                    </div>
                }
            </div>
            <div className="grid-transac mt-3">
                <div className="h5 mb-0 grid-col-span ps-1">Informations</div>
                <div className="border-bottom text-secondary ps-3">N° de carte</div>
                <div className="border-bottom text-primary  ps-3 fw-bolder">{formatCardNumber(props.data.card)}</div>
                <div className="border-bottom text-secondary ps-3">Enseigne</div>
                <div className="border-bottom text-primary  ps-3 fw-bolder">{props.data.enseigne}</div>
                <div className="border-bottom text-secondary ps-3">Code</div>
                <div className="border-bottom text-primary  ps-3 fw-bolder">{props.data.code}</div>

            </div>
            <div className="grid-transac mt-3">
                <div className="h5 mb-0 grid-col-span ps-1">{props.data.libelleSoldePrecendent}</div>
                {
                    (props.data.ModeFonctionnement == 3 || props.data.ModeFonctionnement == 1) &&
                    <>
                        <div className="border-bottom text-secondary ps-3">FID</div>
                        <div className="border-bottom text-primary  ps-3 fw-bolder">{props.data.AncienPME1}</div>
                    </>
                }
                {
                    (props.data.ModeFonctionnement == 3 || props.data.ModeFonctionnement == 2) &&
                    <>
                        <div className="border-bottom text-secondary ps-3">PME</div>
                        <div className="border-bottom text-primary  ps-3  fw-bolder">{props.data.AncienPME2}</div>
                    </>
                }
            </div>

            <div className="grid-transac mt-3">
                <div className="h5 mb-0 grid-col-span ps-1">Transaction</div>

                <div className="border-bottom text-secondary ps-3">Type transaction</div>
                <div className="border-bottom text-primary  ps-3 fw-bolder">{props.data.libelleTransac}</div>
                <div className="border-bottom text-secondary ps-3">{props.data.phraseTransac}</div>
                <div className="border-bottom text-primary  ps-3 fw-bolder">{props.data.montantTransac}</div>
                {
                    (props.data.ModeFonctionnement == 3 || props.data.ModeFonctionnement == 1) &&
                    <>
                        <div className="border-bottom text-secondary ps-3">Spot dans</div>
                        <div className="border-bottom text-primary  ps-3 fw-bolder">{props.data.SpotLeft}</div>
                    </>
                }
                <div className="border-bottom text-secondary ps-3">Date transaction</div>
                <div className="border-bottom text-primary  ps-3 fw-bolder">{moment(props.data.dateTransac).format('DD/MM/YYYY')}</div>
                <div className="border-bottom text-secondary ps-3">Heure transaction</div>
                <div className="border-bottom text-primary  ps-3 fw-bolder">{props.data.heureTransac}</div>
            </div>

            <div className="grid-transac mt-3">
                <div className="h5 mb-0 grid-col-span ps-1">{props.data.libelleNouveauSolde}</div>
                {
                    (props.data.ModeFonctionnement == 3 || props.data.ModeFonctionnement == 1) &&
                    <>
                        <div className="border-bottom text-secondary ps-3">FID</div>
                        <div className="border-bottom text-primary  ps-3 fw-bolder">{props.data.NouveauPME1}</div>
                    </>
                }
                {
                    (props.data.ModeFonctionnement == 3 || props.data.ModeFonctionnement == 2) &&
                    <>
                        <div className="border-bottom text-secondary ps-3">PME</div>
                        <div className="border-bottom text-primary  ps-3  fw-bolder">{props.data.NouveauPME2}</div>
                    </>
                }
            </div>
        </div>
    )
}



const TpeModal = (props) => {
    const { isOpenTpeModal, setIsOpenTpeModal } = props;

    const [isOpenScanModal, setIsOpenScanModal] = useState(false)



    const {
        resetForm,
        values,
        setFieldValue,
        errors,
        handleSubmit,
        handleReset,
        validateForm,
        setErrors
    } = useFormik({
        initialValues: {
            NumCarte: '',
            TypeTransac: '',
            pme: '',
            MontantTransac: '',
            unitLabel: ''
        },
        validateOnBlur: false,
        validateOnChange: false,
        validationSchema: myYup.object().shape({
            NumCarte: myYup.string().required(),
            TypeTransac: myYup.string().required(),
            MontantTransac: myYup.string().test("MontantTransac", "Ce champs est requis", (val) => parseFloat(val) > 0 ? true : false),
        }),
        onSubmit: (values) => { setIsOpenTpeModal(false); CardStore.transac(values, resetForm) },
    });


    const setMontantTransac = (montant, typeTransac) => {
        setFieldValue("TypeTransac", typeTransac);
        console.log('typeTransac', typeTransac)
        if ([1, 2, 5].includes(typeTransac))
            setFieldValue("unitLabel", montant == 1 ? UserStore.props.user.NomUnitePME1 : UserStore.props.user.NomUnitesPME1);
        else if ([3, 4].includes(typeTransac))
            setFieldValue("unitLabel", montant == 1 ? UserStore.props.user.NomUnitePME2 : UserStore.props.user.NomUnitesPME2);

        setFieldValue('MontantTransac', montant);
    };


    const getSign = (typeTransac) => {
        if (!values['TypeTransac'])
            return ''
        if (values['TypeTransac'] && [1, 3, 5].includes(values['TypeTransac']))
            return '+'
        else
            return '-'
    };

    const doTransac = async (card) => {

        if ([2, 4].includes(values['TypeTransac'])) {
            const code = await modalPin()
            const isCorrect = await CardStore.checkPin(card, code)

            if (!isCorrect)
                return
        }

        console.log('doTransac', card)
        setIsOpenTpeModal(false)
        setFieldValue('NumCarte', card)
        // LoadingStore.on()
        await CardStore.transac(values, card, handleReset)
        // LoadingStore.off()
    }

    const openScanModal = (card) => {
        if (Number(values['TypeTransac']) == 0) {
            return modalAlert({
                title: 'Erreur',
                typeAlert: 'danger',
                text: 'Veuillez sélectionner un type de transaction'
            })
        }

        if (Number(values['MontantTransac']) == 0) {
            return modalAlert({
                title: 'Erreur',
                typeAlert: 'danger',
                text: 'Le montant ne peut être nul'
            })
        }
        console.log(parseFloat(values['MontantTransac']))
        setIsOpenScanModal(true)
    }

    useEffect(() => {
        async function init() {
            LoadingStore.on()
            await UserStore.init()
            LoadingStore.off()
        }

        init()

    }, [])


    return (
        <>
            <Modal centered={true} isOpen={isOpenTpeModal}>
                {isOpenTpeModal && (
                    <ModalBody>
                        <h5>Nouvelle transaction</h5>
                        <button className="close-circle" onClick={() => setIsOpenTpeModal(false)}>
                            <X size={25}></X>
                        </button>

                        <label htmlFor="">Type Transaction</label>
                        <div className="row">

                            {
                                !!parseInt(UserStore.props.user.AvecMenuCreditPME1) &&
                                <div className="d-flex form-group mb-3 col-6">
                                    <button
                                        type="button"
                                        className={classNames("btn w-100 btn-outline-success mx-1 py-2 d-flex ac jc position-relative", { selected: values['TypeTransac'] == 1 || values['TypeTransac'] == 5 })}
                                        onClick={() => setMontantTransac(0, UserStore.props.user.idCreditPME1)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" width="35" height="35" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" className="check-circle-floatted" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg> */}

                                        <div className="h3 mb-0 ms-1">Crédit {UserStore.props.user.ModeFonctionnement != 3 ? UserStore.props.user.NomUnitesPME1 : 'FID'}</div>
                                    </button>
                                </div>
                            }
                            {
                                !!parseInt(UserStore.props.user.AvecMenuCreditPME2) &&
                                <div className="d-flex form-group mb-3 col-6">
                                    <button
                                        type="button"
                                        className={classNames("btn w-100 btn-outline-success mx-1 py-2 d-flex ac jc position-relative", { selected: values['TypeTransac'] == 3 })}
                                        onClick={() => setMontantTransac(0, UserStore.props.user.idCreditPME2)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" width="35" height="35" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>

                                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" className="check-circle-floatted" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg> */}
                                        <div className="h3 mb-0 ms-1">Crédit {UserStore.props.user.NomUnitesPME2}</div>
                                    </button>
                                </div>
                            }
                            {
                                !!parseInt(UserStore.props.user.AvecMenuDebitPME1) &&
                                <div className="d-flex form-group mb-3 col-6">
                                    <button
                                        type="button"
                                        className={classNames("btn w-100 btn-outline-danger mx-1 py-2 d-flex ac jc position-relative", { selected: values['TypeTransac'] == 2 })}
                                        onClick={() => setMontantTransac(0, UserStore.props.user.idDebitPME1)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" width="35" height="35" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>

                                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" className="check-circle-floatted" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg> */}
                                        <div className="h3 mb-0 ms-1">Débit {UserStore.props.user.ModeFonctionnement != 3 ? UserStore.props.user.NomUnitesPME1 : 'FID'}</div>
                                    </button>
                                </div>
                            }
                            {
                                !!parseInt(UserStore.props.user.AvecMenuDebitPME2) &&

                                <div className="d-flex form-group mb-3 col-6">
                                    <button
                                        type="button"
                                        className={classNames("btn w-100 btn-outline-danger mx-1 py-2 d-flex ac jc position-relative", { selected: values['TypeTransac'] == 4 })}
                                        onClick={() => setMontantTransac(0, UserStore.props.user.idDebitPME2)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" width="35" height="35" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>

                                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" className="check-circle-floatted" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg> */}
                                        <div className="h3 mb-0 ms-1">Débit {UserStore.props.user.NomUnitesPME2}</div>
                                    </button>

                                </div>
                            }
                            {
                                (errors['TypeTransac'] && <div className="invalid-feedback d-block">{errors['TypeTransac']}</div>)
                            }
                            <div className="form-group col-12">

                                <label>Montant</label>
                                <div className="montant-transac d-flex flex-column">
                                    <div className="d-flex ac">
                                        <span className="montant-transac-sign me-2">{getSign()}</span>
                                        <span className="montant-transac-value">{values["MontantTransac"]}</span>
                                        <span className="ms-2 montant-transac-unit">{values['unitLabel']}</span>
                                    </div>
                                    <div className="d-flex ac">
                                        {getEquivalence(
                                            values["MontantTransac"],
                                            values['TypeTransac'],
                                            UserStore.props.user
                                        )}
                                    </div>
                                </div>
                            </div>
                            {
                                (errors['MontantTransac'] && <div className="invalid-feedback d-block">{errors['MontantTransac']}</div>)
                            }

                        </div>
                        <div className="w-100 d-flex jc mt-3 ">
                            <PinPad
                                setValue={(val) => setMontantTransac(val, values['TypeTransac'])}
                                value={values['MontantTransac']}
                                handleSubmit={() => openScanModal()}
                            ></PinPad>
                        </div>
                        {/* <div className="w-100 d-flex justify-content-between mt-3">
                        <button type="button" className="btn btn-alt w-100" onClick={handleSubmit}>
                            Valider
                        </button>
                    </div> */}
                    </ModalBody>
                )
                }
            </Modal>
            <ScanAllModal
                isOpenScanModal={isOpenScanModal}
                setIsOpenScanModal={setIsOpenScanModal}
                handleSubmit={(card) => doTransac(card)}
            ></ScanAllModal>
        </>
    );
};

export default TpeModal