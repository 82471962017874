import React, { useState } from "react";
import { observer } from "mobx-react";
import CardStore from "../stores/CardStore";
import CreateCardModal from "../modals/CreateCardModal";
import TpeModal from "../modals/TpeModal";
import TransacModal from "../modals/TransacModal";
import UserStore from "../stores/UserStore";
import { modalConfirmWithText } from "../components/Confirm";
import SettingsModal from "../modals/SettingsModal";


const MainPage = observer((props) => {
  const [isOpenCreateCardModal, setIsOpenCreateCardModal] = useState(false);
  const [isOpenTpeModal, setIsOpenTpeModal] = useState(false);
  const [isOpenTransacModal, setIsOpenTransacModal] = useState(false);
  const [isOpenSettingsModal, setIsOpenSettingsModal] = useState(false);

  const openSettings = async () => {

    const next = await modalConfirmWithText({
      title: 'Code',
      text: 'Afin d\'accéder aux paramètres, veuillez saisir le code d\'accès',
      password: '1790'
    })

    if (next)
      setIsOpenSettingsModal(true)

  }

  return (
    <div className="d-flex w-100 ac jc">
      <div className="d-flex flex-column main-box ac jc">
        <div className="w-100 mb-3">
          <button className="main-action card shadow-sm w-100 d-flex flex-column ac jc" onClick={() => setIsOpenCreateCardModal(true)}>

            <svg xmlns="http://www.w3.org/2000/svg" className="big-icon text-primary" fill="none" viewBox="0 0 24 24" strokeWidth="1.7" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>

            <h3 className="text-secondary mt-3">
              Créer une carte
            </h3>

          </button>
        </div>
        <div className="w-100 mb-3">
          <button className="main-action card shadow-sm w-100 d-flex flex-column ac jc" onClick={() => setIsOpenTpeModal(true)}>

            <svg xmlns="http://www.w3.org/2000/svg" className="big-icon text-primary" fill="none" viewBox="0 0 24 24" strokeWidth="1.7" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 21L3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5" />
            </svg>


            <h3 className="text-secondary mt-3">
              Effectuer une transaction
            </h3>

          </button>
        </div>
        <div className="w-100 mb-3">
          <button className="main-action card shadow-sm w-100 d-flex flex-column ac jc" onClick={() => setIsOpenTransacModal(true)}>

            <svg xmlns="http://www.w3.org/2000/svg" className="big-icon text-primary" fill="none" viewBox="0 0 24 24" strokeWidth="1.7" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" d="M21 12a2.25 2.25 0 00-2.25-2.25H15a3 3 0 11-6 0H5.25A2.25 2.25 0 003 12m18 0v6a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 18v-6m18 0V9M3 12V9m18 0a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 9m18 0V6a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 6v3" />
            </svg>


            <h3 className="text-secondary mt-3">
              Solde et transactions
            </h3>

          </button>
        </div>

        <div className="col d-flex w-100 justify-content-between my-3">
          <div className="mx-0">
            <button className="btn btn-secondary" onClick={() => openSettings()}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-7 h-7">
                <path stroke-linecap="round" stroke-linejoin="round" d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z" />
                <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
              </svg>
            </button>
          </div>
          <div className="infos-bottom d-flex flex-column">
            <span className="text-end">{UserStore.props.user.NomCommercant}</span>
            <span className="text-end">{UserStore.props.user.labelMode}</span>
            <span className="text-end">Version {process.env['REACT_APP_VERSION']}</span>
          </div>
        </div>


        {
          isOpenCreateCardModal &&
          <CreateCardModal
            isOpenCreateCardModal={isOpenCreateCardModal}
            fetchReseaux={(search) => CardStore.fetchReseaux(search)}
            setIsOpenCreateCardModal={setIsOpenCreateCardModal}
          ></CreateCardModal>
        }
        {
          isOpenTpeModal &&
          <TpeModal
            isOpenTpeModal={isOpenTpeModal}
            fetchReseaux={(search) => CardStore.fetchReseaux(search)}
            fetchCommercants={(search, reseau) => CardStore.fetchCommercants(search, reseau)}
            setIsOpenTpeModal={setIsOpenTpeModal}
          ></TpeModal>
        }
        {
          isOpenTransacModal &&
          <TransacModal
            init={() => CardStore.init()}
            getTransacs={() => CardStore.getTransacs()}
            numCarte={CardStore.props.NumCarte}
            setNumCarte={(card) => CardStore.setNumCarte(card)}
            columns={CardStore.props.columns}
            filters={{}}
            listActions={CardStore.props.listActions}
            data={CardStore.props.data}
            pagination={CardStore.props.pagination}
            isOpenTransacModal={isOpenTransacModal}
            setIsOpenTransacModal={setIsOpenTransacModal}
          ></TransacModal>
        }
        {
          isOpenSettingsModal &&
          <SettingsModal
            isOpenSettingsModal={isOpenSettingsModal}
            setIsOpenSettingsModal={setIsOpenSettingsModal}
          ></SettingsModal>
        }
      </div>
    </div>
  );
});

export default MainPage;
