import React, { useState } from 'react'
import { Modal, ModalBody } from 'reactstrap'
import { unmountComponentAtNode, render } from 'react-dom';
import parse from 'html-react-parser';
import classNames from 'classnames';
import Input from './Input';


const Confirm = (props) => {

    const { title, text, close } = props

    const [value, setValue] = useState('');
    const [error, setError] = useState(false);
    const [open, setOpen] = useState(true);


    const handleClick = () => {
        setOpen(false)
        close(true)
    }

    return (
        <Modal isOpen={open} backdrop="static" zIndex={1000000000000} centered={true} toggle={() => setOpen(!open)}>
            <ModalBody>
                <h5>
                    Confirmation {title}
                </h5>
                <div className="row">
                    <div className="col">
                        <div className="form-group">
                            <p>{parse(text)}</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 d-flex justify-content-end">
                        <button className="btn-danger btn btn-sm mx-1" onClick={() => { setOpen(!open); close(false) }}>Annuler</button>
                        <button className="btn-success btn btn-sm mx-1" onClick={handleClick}>Confirmer</button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    )
}

const ConfirmCustomer = (props) => {

    const { typeTransac,nom,prenom, close } = props

    const [value, setValue] = useState('');
    const [error, setError] = useState(false);
    const [open, setOpen] = useState(true);


    const handleClick = () => {
        setOpen(false)
        close(true)
    }

    return (
        <Modal isOpen={open} backdrop="static" zIndex={1000000000000} centered={true} toggle={() => setOpen(!open)}>
            <ModalBody>
                <h5>
                    Confirmation {typeTransac}
                </h5>
                <div className="row">
                    <div className="col mb-3">
                        <div className="form-group ">
                            <p>Etes-vous sûr(e) de vouloir sélectionner le client ci-dessous : </p>
                            <h3 className="text-center">{nom}</h3>
                            <h3 className="text-center">{prenom}</h3>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 d-flex justify-content-between">
                        <button className="btn-danger btn  mx-1" onClick={() => { setOpen(!open); close(false) }}>Annuler</button>
                        <button className="btn-success btn mx-1" onClick={handleClick}>Confirmer</button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    )
}


const modalConfirm = (props) => {
    return new Promise(resolve => {
        let el = document.createElement('div');

        const handleResolve = result => {
            unmountComponentAtNode(el);
            el = null;
            resolve(result);
        };

        render(<Confirm {...props} close={handleResolve} />, el);
    });
};

export const  modalConfirmCustomer = (props) => {
    return new Promise(resolve => {
        let el = document.createElement('div');

        const handleResolve = result => {
            unmountComponentAtNode(el);
            el = null;
            resolve(result);
        };

        render(<ConfirmCustomer {...props} close={handleResolve} />, el);
    });
};

export default modalConfirm



const ConfirmWithText = (props) => {

    const { title, text, close, password } = props

    const [value, setValue] = useState('');
    const [error, setError] = useState(false);
    const [open, setOpen] = useState(true);



    const handleClick = () => {
        if (value == password) {
            setOpen(false)
            close(true)
        }
        else
            setError('Code incorrect')
    }

    return (
        <Modal isOpen={open} backdrop="static" zIndex={1000000000000} centered={true} toggle={() => setOpen(!open)}>
            <ModalBody>
                <h5>
                    Confirmation {title}
                </h5>
                <div className="row">
                    <div className="col-12">
                        <div className="form-group">
                            <p>{parse(text)}</p>
                        </div>
                    </div>
                    <div className="form-group floated col-12 mb-2">
                        <label>{title}</label>
                        <Input
                            name="code"
                            type="text"
                            onChange={(name,val) => setValue(val)}
                            value={value}
                            className={"form-control"}
                            error={error}
                        />
                    </div>
                </div>
                <div className="row g-0">
                    <div className="col-12 d-flex justify-space-between">
                        <button className="btn-danger btn mx-1 w-50" onClick={() => { setOpen(!open); close(false) }}>Annuler</button>
                        <button className="btn-success btn mx-1 w-50" onClick={handleClick}>Confirmer</button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    )
}


export const modalConfirmWithText = (props) => {
    return new Promise(resolve => {
        let el = document.createElement('div');

        const handleResolve = result => {
            unmountComponentAtNode(el);
            el = null;
            resolve(result);
        };

        render(<ConfirmWithText {...props} close={handleResolve} />, el);
    });
};


