import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { Navigate, Route } from 'react-router-dom'
import UserStore from '../stores/UserStore'

const IsNotAuthenticated = observer((props) => {

    useEffect(() => {
        UserStore.init()
    }, [props.location])

    const { children } = props
    const isAuth = UserStore.props.user.isAuthenticated


    if (!isAuth) {
        return children
    } else {
        return <Navigate
            replace
            to={{
                pathname: "/",
                state: { name: 'Main', from: location }
            }}
        />
    }


})

export default IsNotAuthenticated