import React from 'react'
import { useFormik } from 'formik'
import { Lock, Mail } from 'react-feather'
import Input from '../components/Input'
import UserStore from '../stores/UserStore'
import { Link, useNavigate } from "react-router-dom";
import { observer } from 'mobx-react';
import logo from "../assets/img/bank-terminal.png";
import logo_fidlink from "../assets/img/logo_fidlink_bleu.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQrcode } from '@fortawesome/free-solid-svg-icons'

const LoginPage = observer((props) => {

    const navigate = useNavigate();

    const redirectIfLoginSuccess = () => navigate({
        pathname: '/dashboard',
        state: { name: 'Tableau de bord' }

    })

    const { errors, values, setFieldValue, handleSubmit } = useFormik({
        initialValues: {
            action: 'login',
            client_id: 'PANOR_TPE_VIRTUEL',
            client_secret: 'PANOR_TPE_VIRTUEL',
            grant_type: 'client_credentials',
        },
        onSubmit: values => UserStore.login(values, redirectIfLoginSuccess),
        validateOnBlur: false,
        validateOnChange: false
    })

    return (
        <div className="hold-transition login-page w-100">
            <div className="login-box mt-5">

                <div className="card">
                    <form className="card-body">
                        <div className="login-logo d-flex ac jc text-white mb-3">
                            <img
                                src={logo_fidlink}
                                alt=""
                                height="100"
                                className=""
                            />
                        </div>
                        <div className="login-logo d-flex ac jc text-white mb-3">
                            <img
                                src={logo}
                                alt=""
                                height="30"
                                className=""
                            />
                            <span className="h4 text-secondary m-0">TPE Virtuel</span>
                        </div>
                        <div className="form-group floated mb-3">
                            <label>Login</label>
                            <Input
                                name="client_id"
                                type="text"
                                className="form-control"
                                onChange={setFieldValue}
                                value={values['client_id']}
                                error={errors['client_id']}
                            />
                        </div>
                        <div className="form-group floated mb-3">
                            <label>Mot de passe</label>
                            <Input
                                name="client_secret"
                                type="password"
                                className="form-control"
                                onChange={setFieldValue}
                                value={values['client_secret']}
                                error={errors['client_secret']}
                            />
                        </div>
                        <div className="row mt-3">
                            <div className="col-12">
                                <button onClick={handleSubmit} className="btn btn-primary w-100">Se connecter</button>
                            </div>
                        </div>
                        {/* <div className="row mt-3">
                            <div className="col-12 h4 mb-0 text-center">
                                ou
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-12">
                                <button onClick={handleSubmit} className="btn btn-primary w-100">
                                    <FontAwesomeIcon icon={faQrcode} size={'lg'} ></FontAwesomeIcon>
                                    <span className="ms-2">QRcode</span>
                                </button>
                            </div>
                        </div> */}
                    </form>
                </div>
            </div>

        </div>
    )
})

export default LoginPage