import React, { useState } from 'react'
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { observer } from 'mobx-react'
import { Modal, ModalBody, ModalFooter } from 'reactstrap'
import scanLogo from "../assets/img/qr-scan.png";
import mobileLogo from "../assets/img/smartphone.png";
import typeLogo from "../assets/img/keyboard.png";
import mobile from "../assets/img/smartphone.png";
import { X } from "react-feather";
import AnimateHeight from 'react-animate-height';
import PinPad from './PinPad';
import myYup from '../utils/myYup';
import classNames from 'classnames';
import { formatCardNumber, formatPhoneNumber, formatShortCardNumber } from '../utils/utils';
import modalAlert from './Alert';
import CardStore from '../stores/CardStore';
import moment from 'moment';
import { modalConfirmCustomer } from './Confirm';

const ScanAllModal = observer((props) => {
    const {
        isOpenScanModal,
        handleSubmit,
        setIsOpenScanModal
    } = props

    const [stopScan, setStopScan] = useState(false)
    const [scanMode, setScanMode] = useState('scan')
    const [numCard, setNumCard] = useState('')
    const [customersList, setCustomersList] = useState([])
    const [tel, setTel] = useState('')

    const onErrorScan = (error) => {
        if (error.name === "NotAllowedError") {
            alert('Vous devez autoriser la caméra')
        }
    };

    const resetAll = () => {
        setScanMode('scan')
        setNumCard('')
        setCustomersList([])
        setTel('')
    }

    const onUpdateScan = (err, result) => {
        console.log('Scan Active')
        try {
            if (result) {
                setStopScan(true)
                setIsOpenScanModal(false)
                console.log(result)
                let data = JSON.parse(result.text)
                let card = data.card
                console.log('onUpdateCard', card)
                handleSubmit(card)
            }
        }
        catch (err) {
            console.log(err)
            alert('Une erreur est survenue')
        }
    };

    const changeMode = (mode) => {
        if (mode != 'scan')
            setStopScan(true)
        else
            setStopScan(false)
        resetAll()
        setScanMode(mode)
    }

    const telSchema = myYup.object().shape({
        tel: myYup.string()
            .required("Le numéro de téléphone du client est requis")
            .matches(/^[0-9]+$/, "Le numéro ne doit contenir que des chiffres")
            .min(10, 'Le numéro doit contenir 10 chiffres')
            .max(10, 'Le numéro doit contenir 10 chiffres')
    })

    const cardSchema = myYup.object().shape({
        card: myYup.string()
            .required("Le numéro de carte du client est requis")
            .matches(/^[0-9]+$/, "Le numéro de carte ne doit contenir que des chiffres")
            .min(9, 'Le numéro de carte doit contenir 9 chiffres')
            .max(9, 'Le numéro de carte doit contenir 9 chiffres')
    })

    const submitTel = async () => {
        try {
            const validation = await telSchema.validate({ tel: tel })


            const list = await CardStore.getCustomersByPhone(tel)
            if (list.length > 0) {
                setCustomersList(list)
                setCustomersList(list)
            } else {
                return modalAlert({
                    title: 'Attention',
                    text: 'Aucun client trouvé pour ce numéro : ' + tel,
                    typeAlert: 'warning'
                })
            }
        }
        catch (err) {
            // console.log(err.message)
            return modalAlert({
                title: 'Attention',
                text: err.message ? err.message : '',
                typeAlert: 'warning'
            })
        }
    }

    const submitNumCard = async () => {
        try {
            const validation = await cardSchema.validate({ card: numCard })
            setStopScan(true)
            setIsOpenScanModal(false)
            setCustomersList([])
            handleSubmit(numCard)
            resetAll()
        }
        catch (err) {
            // console.log(err.message)
            return modalAlert({
                title: 'Attention',
                text: err.message ? err.message : '',
                typeAlert: 'warning'
            })
        }
    }

    const submitCard = async (item) => {
        const next = await modalConfirmCustomer({
            nom: item.nom,
            prenom: item.prenom
        })
        if (next) {
            setStopScan(true)
            setIsOpenScanModal(false)
            setCustomersList([])
            handleSubmit(item.card)
            resetAll()
        }

    }



    return (
        <Modal isOpen={isOpenScanModal} centered={true}>
            <ModalBody>
                {
                    scanMode == 'scan' &&
                    <h5 className="text-secondary">Scan</h5>
                }
                {
                    scanMode == 'manuelle' &&
                    <h5 className="text-secondary">Saisie manuelle</h5>
                }
                {
                    scanMode == 'tel' &&
                    <h5 className="text-secondary">Recherche par n° de téléphone</h5>
                }
                <button className="btn btn-sm btn-primary close-rect d-flex ac jc" onClick={() => setIsOpenScanModal(false)}>
                    <X size={18}></X><span>Fermer</span>
                </button>
                <div className="row">

                    {
                        scanMode == 'scan' &&
                        <div className="col-12 rounded">
                            <div className="d-flex mb-2 ac">
                                <img src={scanLogo} alt="scan" style={{ height: '70px' }} />
                                <p>Veuillez scanner la carte du client</p>
                            </div>
                            <div className="rounded">
                                <BarcodeScannerComponent
                                    className="rounded"
                                    onUpdate={onUpdateScan}
                                    onError={onErrorScan}
                                    stopStream={stopScan}
                                />
                            </div>
                        </div>
                    }

                    {
                        scanMode == 'tel' &&
                        <div className="col-12 rounded d-flex flex-column">
                            {
                                customersList.length == 0 &&
                                <>
                                    <div className="d-flex mb-2 ac">
                                        <img src={mobileLogo} alt="phone" style={{ height: '70px' }} />
                                        <p>Veuillez saisir le n° de téléphone du client</p>
                                    </div>
                                    <div className={classNames("montant-transac d-flex flex-column position-relative mb-4", { 'border-success': tel.length >= 10 })} style={{ height: '70px' }}>
                                        <div className="d-flex ac">
                                            <span className="montant-transac-value">{formatPhoneNumber(tel)}</span>
                                        </div>
                                    </div>
                                    <PinPad
                                        setValue={(val) => setTel(val)}
                                        maxLength={10}
                                        enableZeroFirst={true}
                                        value={tel}
                                        errors={false}
                                        handleSubmit={() => submitTel()}
                                    ></PinPad>
                                </>
                            }

                            {
                                customersList.length > 0 &&
                                <div className="mt-3" style={{ height: '400px', overflowY: 'auto' }}>
                                    {customersList.map(item =>
                                        <div className="card w-100 mb-2 py-2 px-2 d-flex flex-column">
                                            <div className="d-flex flex-row ac">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-primary">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                                                </svg>
                                                <div>
                                                    <table>
                                                        <tr>
                                                            <td className="font-sm" style={{ width: '90px' }}>N° carte</td>
                                                            <td className="fw-bolder">{formatCardNumber(item.card)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="font-sm" style={{ width: '90px' }}>Nom</td>
                                                            <td className="fw-bolder">{item.nom}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="font-sm" style={{ width: '90px' }}>Prénom</td>
                                                            <td className="fw-bolder">{item.prenom}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="font-sm" style={{ width: '90px' }}>Naissance</td>
                                                            <td className="fw-bolder">{moment(item.nele).format('DD/MM/YYYY')}</td>
                                                        </tr>
                                                        {/* <tr>
                                                        <td>N° Téléphone</td>
                                                        <td>{item.tel}</td>
                                                    </tr> */}
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="w-100">
                                                <button className="btn btn-primary w-100" onClick={() => submitCard(item)}>Choisir</button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            }
                        </div>
                    }

                    {
                        scanMode == 'manuelle' &&
                        <div className="col-12 rounded d-flex flex-column">
                            <div className="d-flex mb-2 ac">
                                <img src={typeLogo} alt="phone" style={{ height: '70px' }} />
                                <p className="ps-2">Veuillez saisir le n° de carte du client</p>
                            </div>
                            <div className={classNames("montant-transac d-flex flex-column position-relative mb-4", { 'border-success': numCard.length >= 9 })} style={{ minHeight: '50px' }}>
                                <div className="d-flex ac">
                                    <span className="montant-transac-value" style={{ fontSize: '1.6rem' }}>
                                        <span class="" style={{ fontSize: '1.4rem' }}>9999 9999 </span>
                                        {formatShortCardNumber(numCard)}
                                    </span>
                                </div>
                            </div>
                            <PinPad
                                setValue={(val) => setNumCard(val)}
                                maxLength={9}
                                enableZeroFirst={true}
                                value={numCard}
                                errors={false}
                                handleSubmit={() => submitNumCard()}
                            ></PinPad>
                        </div>
                    }
                    <div className="col-12 rounded">
                        <hr className="w-100"></hr>
                    </div>
                    {
                        scanMode != 'scan' &&
                        <div className="mb-2">
                            <h4 className="text-center">ou</h4>
                            <div className="col-12">
                                <button className="btn btn-lg btn-secondary w-100" onClick={() => changeMode('scan')}>Scanner</button>
                            </div>
                        </div>
                    }
                    {
                        scanMode != 'tel' &&
                        <div className="mb-2">
                            <h4 className="text-center">ou</h4>
                            <div className="col-12">
                                <button className="btn btn-lg btn-secondary w-100" onClick={() => changeMode('tel')}>Rechercher par n° de téléphone</button>
                            </div>
                        </div>
                    }
                    {
                        scanMode != 'manuelle' &&
                        <div className="mb-2">
                            <h4 className="text-center">ou</h4>
                            <div className="col-12">
                                <button className="btn btn-lg btn-secondary w-100" onClick={() => changeMode('manuelle')}>Saisir n° carte manuellement</button>
                            </div>
                        </div>
                    }
                </div>
            </ModalBody>
        </Modal>
    )
})

export default ScanAllModal