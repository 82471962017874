import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { Navigate, Route, withRouter } from 'react-router-dom'
import UserStore from '../stores/UserStore'

const IsAuthenticated = observer((props) => {

    const isAuth = UserStore.props.user.isAuthenticated
    const { children } = props

    useEffect(() => {
        UserStore.init()
    }, [props.location])

    console.log('Middleware ISAUTH', isAuth)


    if (isAuth) {
        return children
    } else {

        return <Navigate
            replace
            to={{
                pathname: "/login",
                state: { name: 'login', from: location }
            }}
        />
    }

})

export default IsAuthenticated