import React, { useState, useEffect } from "react";

import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import NavbarLayout from "./Navbar";
import MainPage from "../pages/MainPage";
import LogoutPage from "../pages/LogoutPage";
import IsAuthenticated from "../middlewares/IsAuthenticated";
import LoginPage from "../pages/LoginPage";
import IsNotAuthenticated from "../middlewares/IsNotAuthenticated";
import UserStore from "../stores/UserStore";
import RegisterPage from "../pages/RegisterPage";





const AppRoute = () => {

  const [ready, setReady] = useState(false)

  useEffect(() => {


    async function doAsync() {
      await UserStore.init()
      setReady(true)
    }
    doAsync()
  }, [])

  return (
    <>
      {
        ready &&
        <Routes>
          <Route exact path="/" element={<IsAuthenticated><NavbarLayout><MainPage /></NavbarLayout></IsAuthenticated>} />
          <Route exact path="/logout" element={<IsAuthenticated><NavbarLayout><LogoutPage /></NavbarLayout></IsAuthenticated>} />
          <Route exact path="/login" element={<IsNotAuthenticated><LoginPage /></IsNotAuthenticated>} />
          <Route exact path="/register/:idRes/:idCom" element={<RegisterPage />} />
        </Routes>
      }
    </>
  );
};

export default AppRoute;
